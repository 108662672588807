import { isArray, isObject } from 'lodash'

export const dataEmptyCleaner = (obj: any): any => {
  if (isArray(obj)) return obj.filter(Boolean)
  if (isObject(obj)) {
    const cloneObj: any = {}
    Object.keys(obj).map((key: string) => {
      cloneObj[key] = dataEmptyCleaner((obj as any)[key])
      return ''
    })
    return cloneObj
  }
  return obj
}

export const removeFalsy = (obj: any) => {
  const newObj: any = {}
  Object.keys(obj).forEach((prop: any) => {
    if (obj[prop]) newObj[prop] = obj[prop]
  })
  return newObj
}
