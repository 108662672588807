import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from 'react-apollo'
import NotificationContext from '../../../contexts/notification.context'
import { dataFormatter } from '../../../helpers/data-formatter'
import { PageHeader, FlexItem } from '../../../layout'
import FormProvider from '../../../form2/form.provider'
import { LOCALE_OPTIONS, FORM_SUBMISSION_STATUS_OPTIONS } from '../../../config/select-options.config'
import { FORM_SUBMISSION_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { IFormSubmission } from '../../../interfaces/form-submission/form-submission-interface'
import { FORM_SUBMISSION_QUERY } from '../../../graphql/form-submission/queries/form-submission'
import {
  UPDATE_FORM_SUBMISSION_MUTATION,
  DELETE_FORM_SUBMISSION_MUTATION,
} from '../../../graphql/form-submission/mutations/form-submission'
import { IOption } from '../../../interfaces/schema/schema.interface'

const FormSubmissionDetails: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { id: qId } = useParams() as { id: string }
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IFormSubmission>()
  const [formOptions, setFormOptions] = useState<IOption[]>()

  /**
   * QUERIES
   */
  const { error: queryError, data: queryData } = useQuery(FORM_SUBMISSION_QUERY, { variables: { id: qId } })
  useEffect(() => {
    if (queryData && queryData.formSubmission) {
      const { formSubmission } = queryData
      const fd = dataFormatter(formSubmission, { populatedFields: ['form'] })
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IFormSubmission)

      if (queryData.forms && queryData.forms.length) {
        setFormOptions(
          queryData.forms.map(({ id: cId, alias: cAlias }: { id: string; alias: string }) => ({
            label: cAlias,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateFormSubmission, { error: mutationError, data: mutationData }] = useMutation(
    UPDATE_FORM_SUBMISSION_MUTATION,
  )
  const [deleteFormSubmission, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_FORM_SUBMISSION_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateFormSubmission) {
      const { updateFormSubmission: updateFormSubmissionData } = mutationData
      const fd = dataFormatter(updateFormSubmissionData, { populatedFields: ['form'] })
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData as IFormSubmission)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Form Submission Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting Form Submission!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Form Submission Deleted!' })
      setTimeout(() => {
        window.location.href = `/form-submissions`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateFormSubmission({ variables: { id: itemID, data: newData } })
  }

  const onDelete = () => {
    deleteFormSubmission({ variables: { id: itemID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  return (
    <div>
      <PageHeader title="Form Submission Details" />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onDelete={onDelete}
            deleteVerifier={qId}
            onSubmit={onUpdate}
            schema={FORM_SUBMISSION_UPDATE_SCHEMA}
            variables={{
              LOCALE_OPTIONS,
              FORM_SUBMISSION_STATUS_OPTIONS,
              FORM_OPTIONS: formOptions,
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default FormSubmissionDetails
