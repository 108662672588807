import styled from 'styled-components/macro'
import rem from '../../helpers/rem.helper'
import { fonts } from '../variables'

export interface IHeading {
  section?: boolean
  moduleItem?: boolean
  module?: boolean
  page?: boolean
  textColor?: string
  amount?: boolean
}

const Heading = styled('h1')<IHeading>`
  font-family: ${fonts.osc};
  color: rgba(0, 0, 0, 0.69);
  margin: ${rem(5)} 0;
  font-weight: 300;
  ${({ page }) =>
    page &&
    `
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.69);
    font-weight: 300;
    margin: 0.3125rem 0px;
  `};
  ${({ section }) =>
    section &&
    `
    font-size: 0.9rem;
    margin: 0;
  `};
  ${({ moduleItem }) =>
    moduleItem &&
    `
    align-self: center;
    font-size: 2.8rem;
    font-weight: 200;
    margin: 40px auto 40px 70px;
  `};

  ${({ module }) =>
    module &&
    `
    margin-bottom: 15px;
    padding: 0px 15px;
    font-weight: 300;
    font-size: 1rem;
  `};

  ${({ amount }) =>
    amount &&
    `
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 25px;
    margin-right: 25px;
  `};

  ${({ textColor }) =>
    textColor &&
    `
    color: ${textColor};
  `};
`

export default Heading
