/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const USER_ME_QUERY = gql`
  query UserMe {
    userMe {
      id
      lastname
      firstname
      email
      app_policy {
        role
        resource
      }
    }
  }
`
