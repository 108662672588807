import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from 'react-apollo'
import NotificationContext from '../../../contexts/notification.context'
import { dataFormatter } from '../../../helpers/data-formatter'
import { PageHeader, FlexItem } from '../../../layout'
import FormProvider from '../../../form2/form.provider'
import { LOCALE_OPTIONS } from '../../../config/select-options.config'
import { WS_PRODUCT_TRANSLATION_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { IWsProductTranslation } from '../../../interfaces/ws-product-translation/ws-product-translation.interface'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { WS_PRODUCT_TRANSLATION_QUERY } from '../../../graphql/ws-product-translation/queries/ws-product-translation'
import { UPDATE_WS_PRODUCT_TRANSLATION_MUTATION } from '../../../graphql/ws-product-translation/mutations/ws-product-translation'

const WsProductTranslationDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { wsid: qwsid, locale: qLocale } = useParams() as { wsid: string; locale: string }
  const localeUC = qLocale ? qLocale.toUpperCase() : ''
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IWsProductTranslation>()
  const [wsProductsOptions, setWsProductsOptions] = useState<IOption[]>()
  const [wsCategoryOptions, setWsCategoryOptions] = useState<IOption[]>()

  /**
   * QUERIES
   */
  const { error: queryError, data: queryData } = useQuery(WS_PRODUCT_TRANSLATION_QUERY, {
    variables: { wsid: qwsid, locale: localeUC },
  })
  useEffect(() => {
    if (queryData && queryData.wsProductTranslation) {
      const { wsProductTranslation } = queryData
      const fd = dataFormatter(wsProductTranslation, {})
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IWsProductTranslation)

      // Products
      if (queryData.wsProducts && queryData.wsProducts.length) {
        setWsProductsOptions(
          queryData.wsProducts.map(({ id: cId, name: cName }: { id: string; name: string }) => ({
            label: cName,
            value: cId,
          })),
        )
      }

      // Categories
      if (queryData.wsCategories && queryData.wsCategories.length) {
        setWsCategoryOptions(
          queryData.wsCategories.map(({ id: cId, name: cName }: { id: string; name: string }) => ({
            label: cName,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateWsProductTranslation, { error: mutationError, data: mutationData }] = useMutation(
    UPDATE_WS_PRODUCT_TRANSLATION_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateWsProductTranslation) {
      const { updateWsProductTranslation: updateWsProductTranslationData } = mutationData
      const fd = dataFormatter(updateWsProductTranslationData, {})
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData as IWsProductTranslation)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Translation Updated!' })
    }
  }, [mutationData])

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateWsProductTranslation({ variables: { id: itemID, data: newData } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null
  const { slug } = snapShot

  return (
    <div>
      <PageHeader title="Category Translation Details" />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            schema={WS_PRODUCT_TRANSLATION_UPDATE_SCHEMA}
            variables={{
              LOCALE_OPTIONS,
              WS_PRODUCT_OPTIONS: wsProductsOptions || [],
              WS_CATEGORY_OPTIONS: wsCategoryOptions || [],
            }}
            data={snapShot}
            deleteVerifier={slug}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default WsProductTranslationDetailsPage
