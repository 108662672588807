import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery, useMutation } from 'react-apollo'
import PopupContext from '../../../contexts/popup.context'
import NotificationContext from '../../../contexts/notification.context'
import { removeFalsy } from '../../../helpers/object.helper'
import { WS_CATEGORIES_QUERY } from '../../../graphql/ws-category/queries/ws-category'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { IWsCategory } from '../../../interfaces/ws-category/ws-category.interface'
import { PageHeader, FlexItem, Grid, Button } from '../../../layout'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { FilterSearch, FilterDropdown, Pagination } from '../../../components'
import { LOCALE_OPTIONS, WS_CATEGORIES_ORDER_BY_OPTIONS, STATUS_OPTIONS } from '../../../config/select-options.config'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { CREATE_WS_CATEGORY_MUTATION } from '../../../graphql/ws-category/mutations/ws-category'
import { DEFAULT_STATUS, LOCALES, DEFAULT_LOCALE } from '../../../config/variables.config'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { WS_CATEGORY_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { CREATE_WS_CATEGORY_TRANSLATION_MUTATION } from '../../../graphql/ws-category-translation/mutations/ws-category-translation'
import DarkModeContext from '../../../contexts/darkmode.context'

interface IWsCategoryListQuery {
  wsid?: string
  locale?: string
  search?: string
  status?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'name_ASC'

const WsCategoryList = () => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IWsCategoryListQuery>({ limit: '10' })

  // QUERY
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(WS_CATEGORIES_QUERY, {
    variables: query,
  })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const {
        orderBy = DEFAULT_ORDER_BY,
        limit = DEFAULT_LIMIT,
        skip = DEFAULT_SKIP,
      } = searchQuery as IWsCategoryListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])

  // MUTATION
  const [createWsCategoryTranslation] = useMutation(CREATE_WS_CATEGORY_TRANSLATION_MUTATION)
  const [createWsCategory, { error: mutationError, data: mutationData }] = useMutation(CREATE_WS_CATEGORY_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.createWsCategory) {
      const { createWsCategory: createWsCategoryData } = mutationData

      LOCALES.forEach(locale => {
        if (locale !== DEFAULT_LOCALE) {
          createWsCategoryTranslation({
            variables: {
              data: {
                wsid: createWsCategoryData.wsid,
                locale,
                name: createWsCategoryData.name,
                slug: createWsCategoryData.slug,
              },
            },
          })
        }
      })

      window.location.href = `ws-category/${createWsCategoryData.slug}`
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully created!' })
    }
  }, [mutationData])

  // POPUPS
  const onClickCreate = () => {
    const initialData = {
      status: DEFAULT_STATUS,
      seo: { create: {} },
    }

    popupCtx.show({
      id: 'create-ws-category',
      type: FORM_POPUP,
      options: {
        schema: WS_CATEGORY_CREATE_SCHEMA,
        data: initialData,
        variables: { STATUS_OPTIONS, LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createWsCategory({ variables: { data: cbdata } }),
    })
  }

  //  COMPONENT LOGIC
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { wsCategoryCount, wsCategories: qWsCategories = [] } =
    queryData || ({} as { wsCategoryCount: number; wsCategories: IWsCategory[] })
  const wsCategories: IWsCategory[] = qWsCategories as IWsCategory[]

  return (
    <div>
      <PageHeader title="Categories" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Actions</SectionHeader>
            <Grid mdCol="1fr auto" gap="20px" alignContent="end" justifyContent="start" alignItems="end">
              <FilterSearch
                name="search"
                label="Search (in name and slug)"
                value={query.search}
                onChange={value => pushQuery('search', value)}
              />
              <Button ghost onClick={onClickCreate}>
                Create New Category
              </Button>
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={2} gap="20px">
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={WS_CATEGORIES_ORDER_BY_OPTIONS as [IOption]}
                onChange={value => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={STATUS_OPTIONS as [IOption]}
                onChange={value => pushQuery('status', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Name</Th>
                  <Th>Slug</Th>
                  <Th>Status</Th>
                  <Th>wsid</Th>
                  <Th>id</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!wsCategories.length &&
                    wsCategories.map(({ id, wsid, name, slug, status }) => {
                      return (
                        <Tr key={id}>
                          <Td>
                            <NavLink to={`/ws-category/${slug}`}>{name}</NavLink>
                          </Td>
                          <Td>{slug}</Td>
                          <Td>{status}</Td>
                          <Td>{wsid}</Td>
                          <Td>{id}</Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={wsCategoryCount} pushQuery={pushQuery} query={query} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default WsCategoryList
