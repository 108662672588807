/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useContext } from 'react'
import RichTextEditor from 'react-rte'
import { isEqual } from 'lodash'
import { Grid, FlexItem } from '../../layout'
import { Label } from '../../form2/fields/input/input.styles'
import { IInputProps } from '../../form2/form.provider'
import { RteWrapper } from './rte.styles'
import DarkModeContext from '../../contexts/darkmode.context'

const RichTextedit: React.FC<IInputProps> = props => {
  const inputRef = useRef(null)
  const [snapshot, setSnapshot] = useState()
  const [newValue, setNewValue] = useState()
  const { setValue, value, fieldInfo } = props
  const { name, config } = fieldInfo
  const { flex, alternative } = config
  const { required, label, placeholder, validationError } = config

  const DarkModeCtx = useContext(DarkModeContext)
  const { dark } = DarkModeCtx

  useEffect(() => {
    const objectValue = RichTextEditor.createValueFromString(value || '', 'markdown')
    if (!isEqual(snapshot, objectValue)) setSnapshot(objectValue)

    if (!isEqual(newValue, objectValue)) {
      setNewValue(value)
    }
  }, [value])

  useEffect(() => {
    if ((newValue || newValue === '') && !isEqual(newValue, value)) {
      setValue({ [name as string]: newValue })
    }
  }, [newValue])

  const onChange = (updatedValue: any) => {
    setSnapshot(updatedValue)
  }

  const onBlurChange = () => {
    const newStringSnapshot = snapshot.toString('markdown')
    setNewValue(newStringSnapshot)
  }

  const toolbarConfig: any = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
      'IMAGE_BUTTON',
    ],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
      { label: 'Blockquote', style: 'blockquote' },
    ],
  }

  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <Grid gap="10px">
        <Label onBlur={onBlurChange} htmlFor={name} dark={dark} alternative={alternative}>
          {label}
        </Label>
        <RteWrapper type="button" onBlur={onBlurChange} alternative={alternative} dark={dark}>
          <RichTextEditor
            value={snapshot || RichTextEditor.createEmptyValue()}
            toolbarConfig={toolbarConfig}
            placeholder={placeholder}
            onChange={onChange}
            ref={inputRef}
            // disabled={disabled}
          />
        </RteWrapper>
        <input
          style={{ opacity: 0, height: '0px' }}
          autoComplete="off"
          required={required}
          value={newValue}
          tabIndex={-1}
          name={name}
        />
        {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
      </Grid>
    </FlexItem>
  )
}

export default RichTextedit
