/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const WS_CATEGORY_QUERY = gql`
  query WsCategory($slug: String!) {
    wsCategory(where: { slug: $slug }) {
      id
      wsid
      status
      name
      slug
      description
      seo {
        title
        description
        keywords
        image
      }
      position
      featured_image
      thumbnail_image
    }
  }
`

export const WS_CATEGORIES_QUERY = gql`
  query WsCategories(
    $locale: Locale
    $search: String
    $orderBy: WsCategoryOrderByInput
    $skip: Int
    $limit: Int
    $status: Status
  ) {
    wsCategories(
      where: { locale: $locale, status: $status }
      searchInNameAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      wsid
      status
      name
      slug
      position
      updated_at
    }
    wsCategoryCount(where: { locale: $locale, status: $status }, searchInNameAndSlug: $search)
  }
`
