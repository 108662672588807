import React, { useState } from 'react'
import NotificationContext from '../../contexts/notification.context'
import { NotificationListWrapper } from './notification.styles'
import Notification from './notification.component'

export interface INotificationItem {
  id?: string
  type: string
  icon: string
  message: string
}

const NotificationProvider: React.FC<any> = props => {
  const { children } = props
  const [list, setList] = useState<INotificationItem[]>([])

  const show = (newItem: INotificationItem) => {
    setList([...list, { ...newItem, id: `${list.length + 1}` }])
  }

  const hide = (id: string) => {
    const items: INotificationItem[] = []
    list.map((item: INotificationItem) => {
      if (id !== item.id) items.push(item)
      return ''
    })
    setList(items)
  }

  return (
    <NotificationContext.Provider value={{ show }}>
      <NotificationListWrapper>
        {!!list.length &&
          list.map(notification => {
            const { id } = notification
            return <Notification key={id} notification={notification} hide={hide} />
          })}
      </NotificationListWrapper>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
