/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useQuery } from 'react-apollo'
import { PageHeader, FlexItem, Heading, Grid, ButtonLink } from '../../../layout'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { WS_ORDER_QUERY } from '../../../graphql/ws-order/queries/ws-order'
import { IWsOrder } from '../../../interfaces/ws-order/ws-order-interface'
import { MAIN_BRAND_COLOR } from '../../../config/layout.config'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { DetailsWrapper, DetailHeader, DetailValue } from './ws-order0details-overview.styes'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'

const WsOrderDetailsOverView: React.FC = () => {
  const { id: qId } = useParams() as { id: string }

  /**
   * QUERIES
   */
  const { error: queryError, data: queryData } = useQuery(WS_ORDER_QUERY, { variables: { id: qId } })

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!queryData) return null
  if (!queryData.wsOrder) return null

  const {
    email,
    total_net,
    total_gross,
    contact_details,
    billing_details,
    shipping_details,
    order_status,
    payment_status,
    shipping_status,
    items,
  } = queryData.wsOrder as IWsOrder

  return (
    <div>
      <PageHeader title="Order Details" />
      <ButtonLink absoluteTopRight to={`/ws-order/edit/${qId}`}>
        Modify Order
      </ButtonLink>
      <FlexItem margin="-75px 25px 155px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark>Ordered Products</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Product</Th>
                  <Th>Variations</Th>
                  <Th>Price</Th>
                  <Th>Discount</Th>
                  <Th>Discounted Price</Th>
                  <Th>Tax</Th>
                  <Th>Quantity</Th>
                  <Th>Subtotal</Th>
                </Thead>
                <Tbody>
                  {!!items.length &&
                    items.map((item, i) => {
                      const {
                        product,
                        variations,
                        unit_price,
                        product_price,
                        variation_price,
                        discount,
                        tax,
                        total_net: iTN,
                        total_gross: iTG,
                        quantity,
                      } = item
                      const { wsid, name: pName, price: pPrice } = product
                      const index = i

                      let variationTotalPrice = 0

                      return (
                        <Tr key={index}>
                          <Td>
                            <NavLink to={`/ws-product/${wsid}`}>
                              {pName} [{pPrice} EUR]
                            </NavLink>
                          </Td>
                          <Td>
                            {!!variations.length &&
                              variations.map(variation => {
                                const { name: vName, value: vValue, price: vPrice } = variation
                                variationTotalPrice += vPrice
                                return (
                                  <p key={`${vName}-${vValue}-${vPrice}`}>
                                    {vName}: {vValue} [+{vPrice} EUR]
                                  </p>
                                )
                              })}
                          </Td>
                          <Td>{product_price + variation_price}</Td>
                          <Td>{discount} %</Td>
                          <Td>{unit_price}</Td>
                          <Td>{tax} %</Td>
                          <Td>{quantity}</Td>
                          <Td>
                            <strong>{iTN} EUR Net</strong>
                            <p>{iTG} EUR Gross</p>
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
              <FlexItem margin="0px 0px 15px 0px">
                <Heading as="h6" amount textColor={MAIN_BRAND_COLOR}>
                  Total Net: {total_net} EUR
                </Heading>
                <Heading as="h6" amount textColor="black">
                  Total Gross: {total_gross} EUR
                </Heading>
              </FlexItem>
            </Grid>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader dark>Status</SectionHeader>
            <DetailsWrapper>
              <Grid mdCol="1fr auto" gap="0px">
                <DetailHeader>
                  <p>Order Status</p>
                  <p>Payment Status</p>
                  <p>Shipping Status</p>
                </DetailHeader>
                <DetailValue>
                  <p>{order_status}</p>
                  <p>{payment_status}</p>
                  <p>{shipping_status}</p>
                </DetailValue>
              </Grid>
            </DetailsWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader dark>Contact Details</SectionHeader>
            <DetailsWrapper>
              <Grid mdCol="1fr auto" gap="0px">
                <DetailHeader>
                  <p>Email</p>
                  <p>Firstname</p>
                  <p>Lastname</p>
                  <p>Phone</p>
                </DetailHeader>
                <DetailValue>
                  <p>{email}</p>
                  <p>{contact_details.firstname}</p>
                  <p>{contact_details.lastname}</p>
                  <p>{contact_details.phone}</p>
                </DetailValue>
              </Grid>
            </DetailsWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader dark>Billing Details</SectionHeader>
            <DetailsWrapper>
              <Grid mdCol="1fr auto" gap="0px">
                <DetailHeader>
                  <p>Firstname</p>
                  <p>Lastname</p>
                  <p>Company</p>
                  <p>Country</p>
                  <p>Region</p>
                  <p>Locality</p>
                  <p>Postal Code</p>
                  <p>Street Address</p>
                </DetailHeader>
                <DetailValue>
                  <p>{billing_details.firstname || ''}</p>
                  <p>{billing_details.lastname || ''}</p>
                  <p>{billing_details.company || ''}</p>
                  <p>{billing_details.country || ''}</p>
                  <p>{billing_details.region || ''}</p>
                  <p>{billing_details.locality || ''}</p>
                  <p>{billing_details.postal_code || ''}</p>
                  <p>{billing_details.street_address || ''}</p>
                </DetailValue>
              </Grid>
            </DetailsWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader dark>Shipping Details</SectionHeader>
            <DetailsWrapper>
              <Grid mdCol="1fr auto" gap="0px">
                <DetailHeader>
                  <p>Firstname</p>
                  <p>Lastname</p>
                  <p>Country</p>
                  <p>Region</p>
                  <p>Locality</p>
                  <p>Postal Code</p>
                  <p>Street Address</p>
                </DetailHeader>
                <DetailValue>
                  <p>{shipping_details.firstname || ''}</p>
                  <p>{shipping_details.lastname || ''}</p>
                  <p>{shipping_details.country || ''}</p>
                  <p>{shipping_details.region || ''}</p>
                  <p>{shipping_details.locality || ''}</p>
                  <p>{shipping_details.postal_code || ''}</p>
                  <p>{shipping_details.street_address || ''}</p>
                </DetailValue>
              </Grid>
            </DetailsWrapper>
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default WsOrderDetailsOverView
