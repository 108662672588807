/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const TRANSLATION_QUERY = gql`
  query Translation($locale: Locale!, $id: ObjectID!) {
    translation(where: { locale: $locale, id: $id }) {
      id
      locale
      key
      value
    }
  }
`

export const TRANSLATIONS_QUERY = gql`
  query Translations(
    $locale: Locale
    $search: String
    $orderBy: TranslationOrderByInput
    $skip: Int
    $limit: Int
    $id: ObjectID
  ) {
    translations(
      where: { locale: $locale, id: $id }
      searchInKeyAndValue: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      locale
      key
      value
      created_at
      updated_at
    }
    translationCount(where: { locale: $locale, id: $id }, searchInKeyAndValue: $search)
  }
`
