/**
 * general
 */
export const DEFAULT_LOCALE = 'EN'
export const DEFAULT_STATUS = 'DRAFT'
export const LOCALES = ['EN', 'DE', 'HU']
/**
 * auth
 */
export const AUTH_HEADER = 'Aerylabs-Auth'
export const AUTH_COOKIE = 'authorization'
export const RESOURCE = 'FARKASTENT_SERVER'
export const WHITELISTED_ROLES = ['ADMIN']
/**
 * Graphql
 */
export const SERVER_URI = 'https://api.farkastent.com'
