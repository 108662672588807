/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useContext } from 'react'
import { isArray, isEqual } from 'lodash'
import { generateMongoObjectID } from '../../../helpers/mongoid-generator.helper'
import InputField from '../input/input.field'
import { IInputProps } from '../../form.provider'
import SelectField from '../select/select.field'
import { IOption } from '../../../interfaces/schema/schema.interface'
import ArrayField from '../array/array.field'
import { FlexItem } from '../../../layout'
import { SectionHeader, SectionWrapper } from '../../../layout/section/section.styles'
import RichTextedit from '../../../components/editors/rte.component'
import NestedFormField from '../nested-form/nested-form.field'
import MultiselectField from '../multiselect/multiselect.field'
import DarkModeContext from '../../../contexts/darkmode.context'

const ObjectField: React.FC<IInputProps> = props => {
  const { providedVars, requiredVars, value, fieldInfo, getValues, setValue: pSetValue, actualIndex } = props
  const { name, fields, config } = fieldInfo
  const { label, flex } = config
  const [snapshot, setSnapshot] = useState<any>()

  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx

  useEffect(() => {
    if (!isEqual(snapshot, value)) setSnapshot(value)
  }, [value])

  useEffect(() => {
    if (snapshot && !isEqual(snapshot, value)) {
      pSetValue({ [name as string]: snapshot }, actualIndex)
    }
  }, [snapshot])

  const setValue = (v: any) => {
    setSnapshot({ ...snapshot, ...v })
  }
  if (!snapshot) return null

  return (
    <FlexItem flex="100%">
      {label && <SectionHeader dark={dark}>{label}</SectionHeader>}
      <FlexItem flex={flex || '100%'} alignItems="start">
        {fields &&
          fields.map(field => {
            const { type, name: fName, config: fConfig } = field

            if (type === 'INPUT') {
              return (
                <InputField
                  key={fName}
                  value={snapshot[fName]}
                  getValues={getValues}
                  setValue={setValue}
                  fieldInfo={field}
                  providedVars={providedVars}
                  requiredVars={requiredVars}
                />
              )
            }

            if (type === 'WYSIWYG') {
              return (
                <RichTextedit
                  key={fName}
                  value={snapshot[fName]}
                  getValues={getValues}
                  setValue={setValue}
                  fieldInfo={field}
                  providedVars={providedVars}
                  requiredVars={requiredVars}
                />
              )
            }

            if (type === 'OBJECT_ID') {
              return (
                <div key={name} style={{ opacity: 0, position: 'absolute', top: '0', left: '0' }}>
                  <input disabled name={name} defaultValue={snapshot[name] || generateMongoObjectID()} />
                </div>
              )
            }

            if (type === 'NESTED_FORM') {
              const { formName, flex: fFlex, defaultValue } = fConfig
              // handle default values
              if (!snapshot[fName] && defaultValue) {
                setValue({ [fName as string]: defaultValue })
              }

              return (
                <FlexItem flex={fFlex || '100%'} margin="20px 20px 0 0">
                  <SectionWrapper>
                    <NestedFormField
                      key={fName}
                      value={snapshot[fName]}
                      id={snapshot.id}
                      form={formName}
                      getValues={getValues}
                      setValue={setValue}
                      fieldInfo={field}
                      providedVars={providedVars}
                      requiredVars={requiredVars}
                      fullWidth
                    />
                  </SectionWrapper>
                </FlexItem>
              )
            }

            /**
             * MODULE RELEATED LOGIC
             */
            if (type === 'MODULE_TYPE') {
              const { flex: fFlex } = fConfig
              return (
                <FlexItem flex={fFlex || '100%'} margin="20px 20px 0 0">
                  <InputField
                    moduleInput
                    key={fName}
                    value={snapshot[fName]}
                    getValues={getValues}
                    setValue={setValue}
                    fieldInfo={field}
                    providedVars={providedVars}
                    requiredVars={requiredVars}
                  />
                </FlexItem>
              )
            }
            if (type === 'MODULE_DATA') {
              const { flex: fFlex } = fConfig
              return (
                <FlexItem flex={fFlex || '100%'} margin="20px 20px 0 0">
                  <NestedFormField
                    key={fName}
                    value={snapshot[fName]}
                    id={snapshot.id}
                    form={snapshot.type || null}
                    getValues={getValues}
                    setValue={setValue}
                    fieldInfo={field}
                    providedVars={providedVars}
                    requiredVars={requiredVars}
                  />
                </FlexItem>
              )
            }
            if (type === 'MODULE_INFO') {
              const { flex: fFlex } = fConfig
              return (
                <FlexItem flex={fFlex || '100%'} margin="20px 20px 0 0">
                  <NestedFormField
                    key={fName}
                    value={snapshot[fName]}
                    id={snapshot.id}
                    form="Info"
                    getValues={getValues}
                    setValue={setValue}
                    fieldInfo={field}
                    providedVars={providedVars}
                    requiredVars={requiredVars}
                  />
                </FlexItem>
              )
            }

            /**
             * NESTED OBJECT LOGIC
             */
            if (type === 'NESTED_OBJECT' && !label) {
              const { flex: fFlex } = fConfig
              return (
                <FlexItem key={fName} flex={fFlex || '100%'} margin="20px 20px 0 0">
                  <SectionWrapper>
                    <ObjectField
                      key={fName}
                      getValues={getValues}
                      setValue={setValue}
                      value={snapshot[fName]}
                      fieldInfo={field}
                      providedVars={providedVars}
                      requiredVars={requiredVars}
                      actualIndex={actualIndex}
                    />
                  </SectionWrapper>
                </FlexItem>
              )
            }
            if (type === 'NESTED_OBJECT' && !!label) {
              return (
                <ObjectField
                  key={fName}
                  getValues={getValues}
                  setValue={setValue}
                  value={snapshot[fName]}
                  fieldInfo={field}
                  providedVars={providedVars}
                  requiredVars={requiredVars}
                  actualIndex={actualIndex}
                />
              )
            }

            if (type === 'ARRAY_OF_OBJECTS') {
              return (
                <ArrayField
                  key={fName}
                  getValues={getValues}
                  setValue={setValue}
                  value={snapshot[fName]}
                  fieldInfo={field}
                  providedVars={providedVars}
                  requiredVars={requiredVars}
                  actualIndex={actualIndex}
                />
              )
            }

            if (type === 'SELECT' || type === 'MULTISELECT') {
              /**
               * Agregate variables data in options config
               * TODO - create new helper fot this
               */
              const { options } = fConfig
              const optionsAsArray = options as [IOption]
              let agregatedOptions = optionsAsArray
              if (typeof options === 'string' && requiredVars && requiredVars.includes(options as string)) {
                if (!isArray(providedVars[options])) {
                  return (
                    <>
                      <div style={{ color: 'red' }}>Provided options is not an array or wrong format!</div>
                      <input disabled name={name} defaultValue={snapshot[name]} />
                    </>
                  )
                }
                agregatedOptions = providedVars[options] as [IOption]
              }
              if (!agregatedOptions || !agregatedOptions.length) {
                return (
                  <>
                    <div style={{ color: 'red' }}>No provided options!</div>
                    <input disabled name={name} defaultValue={snapshot[name]} />
                  </>
                )
              }

              if (type === 'SELECT') {
                return (
                  <SelectField
                    key={fName}
                    value={snapshot[fName]}
                    getValues={getValues}
                    setValue={setValue}
                    fieldInfo={{ ...field, config: { ...field.config, options: agregatedOptions } }}
                    providedVars={providedVars}
                    requiredVars={requiredVars}
                  />
                )
              }
              if (type === 'MULTISELECT') {
                return (
                  <MultiselectField
                    key={fName}
                    value={snapshot[fName]}
                    getValues={getValues}
                    setValue={setValue}
                    fieldInfo={{ ...field, config: { ...field.config, options: agregatedOptions } }}
                    providedVars={providedVars}
                    requiredVars={requiredVars}
                  />
                )
              }
            }

            return null
          })}
      </FlexItem>
    </FlexItem>
  )
}

export default ObjectField
