import React, { useState } from 'react'
import PopupContext, { IPopupItem } from '../../contexts/popup.context'
import componentsMap, { IPopupList } from './popup.map'

const PopupProvider: React.FC<any> = props => {
  const { children } = props
  const [popups, setPopups] = useState<IPopupItem[]>([])

  const show = (newItem: IPopupItem) => {
    let isAlreadyExist = false
    popups.map(({ id }) => {
      if (id === newItem.id) isAlreadyExist = true
      return ''
    })
    if (!isAlreadyExist) setPopups([...popups, newItem])
  }

  const hide = (id: string) => {
    const items: IPopupItem[] = []
    popups.map((item: IPopupItem) => {
      if (id !== item.id) items.push(item)
      return ''
    })
    setPopups(items)
  }

  return (
    <div>
      <PopupContext.Provider value={{ show }}>
        {!!popups.length &&
          popups.map(popup => {
            const { id, type, submit, options } = popup
            const map: IPopupList = componentsMap as any
            const DynamicComponent: React.FC<any> = map[type] as any
            return <DynamicComponent key={id} id={id} type={type} hide={hide} submit={submit} options={options} />
          })}
        {children}
      </PopupContext.Provider>
    </div>
  )
}

export default PopupProvider
