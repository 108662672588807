/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Icon from '../../layout/icons/icons.styles'
import { SidebarItemStyles, SidebarItemPlaceholder, SidebarItemTitle, SidebarItemLink, Title } from './sidebar.styles'
import { Grid } from '../../layout'

export interface ISidebar {
  addFunction?: boolean
  type?: string
  image?: string
  url?: string
  icon?: string
  isActive?: boolean
  isFixed?: boolean
  onClick?: any
  title?: string
  open?: boolean
  layout?: string
}

const SidebarItem: React.FC<ISidebar> = ({
  addFunction = false,
  type = 'parent',
  url = '/',
  icon = '',
  isActive,
  isFixed,
  onClick,
  title,
  open,
}) => (
  <SidebarItemStyles
    {...(type === 'parent' || addFunction ? { onClick: () => onClick() } : {})}
    isFixed={isFixed}
    active={isActive}
    type={type}
    open={open}
    role="none"
    pills
  >
    {type === 'parent' && (
      <SidebarItemTitle>
        <Grid mdCol="auto 1fr" col="auto 1fr" gap="16px">
          {icon ? <Icon {...{ [icon]: true }} sidebar style={{ fontSize: '18px' }} /> : <SidebarItemPlaceholder />}
          <Title visible={isFixed}>{title}</Title>
        </Grid>
      </SidebarItemTitle>
    )}
    {type === 'child' && !/(http|https):\/\//.test(url) && (
      <SidebarItemLink to={url} activeClassName="active">
        <Grid mdCol="auto 1fr" col="auto 1fr" gap="16px">
          {icon ? <Icon {...{ [icon]: true }} sidebar style={{ fontSize: '18px' }} /> : <SidebarItemPlaceholder />}
          <Title visible={isFixed}>{title}</Title>
        </Grid>
      </SidebarItemLink>
    )}
    {type === 'child' && /(http|https):\/\//.test(url) && (
      <SidebarItemTitle href={url} target="_blank" rel="noopener noreferrer">
        <Grid mdCol="auto 1fr" col="auto 1fr" gap="16px">
          {icon ? <Icon {...{ [icon]: true }} sidebar style={{ fontSize: '18px' }} /> : <SidebarItemPlaceholder />}
          <Title visible={isFixed}>{title}</Title>
        </Grid>
      </SidebarItemTitle>
    )}
  </SidebarItemStyles>
)

export default SidebarItem
