/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { STATUS_OPTIONS, LOCALE_OPTIONS, FORM_FIELDS_OPTIONS } from '../../../config/select-options.config'
import NotificationContext from '../../../contexts/notification.context'
import { PageHeader, FlexItem } from '../../../layout'
import { IForm } from '../../../interfaces/form/form-interface'
import { FORM_QUERY } from '../../../graphql/form/queries/form'
import { UPDATE_FORM_MUTATION, DELETE_FORM_MUTATION } from '../../../graphql/form/mutations/form'
import { FORM_UPDATE_SCHEMA } from '../../../schemas/schema.map'

const FormDetails: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, slug: qSlug } = useParams() as { locale: string; slug: string }
  const capsLocale = qLocale.toUpperCase()
  const [formID, setFormID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IForm>()

  /**
   * QUERIES
   */
  const { error: queryError, data: queryData } = useQuery(FORM_QUERY, {
    variables: { locale: capsLocale, slug: qSlug },
  })
  useEffect(() => {
    if (queryData && queryData.form) {
      const { form } = queryData

      // set defaults to nested objects
      if (!form.success_info) form.success_info = { title: '', description: '' }
      if (!form.error_info) form.error_info = { title: '', description: '' }
      if (!form.mailer_info) form.mailer_info = {}

      const fd = dataFormatter(form, {})
      const { id, ...formattedData } = fd
      setFormID(id)
      setSnapshot(formattedData as IForm)
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updatePage, { error: mutationError, data: mutationData }] = useMutation(UPDATE_FORM_MUTATION)
  const [deletePage, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(DELETE_FORM_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateForm) {
      const { updateForm: updateFormData } = mutationData
      const fd = dataFormatter(updateFormData, {})
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Form Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting form!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Form Deleted!' })
      setTimeout(() => {
        window.location.href = `/forms`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * POPUPS
   */

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updatePage({ variables: { id: formID, data: newData } })
  }

  const onDelete = () => {
    deletePage({ variables: { id: formID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { locale, title, slug } = snapShot

  return (
    <div>
      <PageHeader title={`Form Details - ${title} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onDelete={onDelete}
            deleteVerifier={slug}
            onSubmit={onUpdate}
            schema={FORM_UPDATE_SCHEMA}
            variables={{ STATUS_OPTIONS, LOCALE_OPTIONS, FORM_FIELDS_OPTIONS }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default FormDetails
