/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react'
import Cookies from 'universal-cookie'
import Logo from '../../assets/images/aery_logo_icon.png'
import SidebarItem from './sidebar-item.component'
import {
  SidebarStyles,
  SidebarWrapper,
  SidebarContainer,
  SidebarItemStyles,
  SidebarFixer,
  HomeLink,
} from './sidebar.styles'
import Icon from '../../layout/icons/icons.styles'
import { Button } from '../../layout'
import { IUser } from '../../interfaces/user/user.interface'
import { BRAND_IMAGE } from '../../config/layout.config'
import DarkModeContext from '../../contexts/darkmode.context'

interface ISidebar {
  user: IUser
  isOpen: boolean
  setSideBar: (val: boolean) => void
  history?: any
}

const Sidebar = (props: ISidebar) => {
  const { isOpen, setSideBar, user, history = { location: { pathname: '' } } } = props
  const { location: { pathname = '' } = { pathname: '' } } = history
  const isActiveSettings = pathname === '/settings' || pathname === '/dictionary' || pathname === '/routing'

  const [settingsToggle, setSettingsToggle] = useState(isActiveSettings)
  const [userToggle, setUserToggle] = useState(false)

  const darkModeCtx = useContext(DarkModeContext)

  const onLogOut = () => {
    const cookies = new Cookies()
    cookies.set('authorization', '', { path: '/' })
    window.location.href = '/'
  }

  if (!user) return null
  const { firstname, lastname } = user

  return (
    <SidebarStyles fixed={isOpen} backgroundImage={BRAND_IMAGE} {...darkModeCtx}>
      <SidebarWrapper>
        <SidebarContainer bottomLine>
          <SidebarItemStyles header pills={false}>
            <img src={Logo} alt="" />
            <HomeLink to="/" visible={isOpen}>
              Aery Labs
            </HomeLink>

            <SidebarFixer fixed={isOpen}>
              <Button
                fixer
                onClick={() => {
                  setSideBar(!isOpen)
                }}
              >
                <Icon opensidebar={!isOpen} closesidebar={isOpen} />
              </Button>
            </SidebarFixer>
          </SidebarItemStyles>
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            onClick={() => setUserToggle(!userToggle)}
            isActive={pathname === '/profile'}
            title={`${firstname} ${lastname}`}
            open={userToggle}
            isFixed={isOpen}
            type="parent"
            layout="pils"
            icon="user"
          />
          <SidebarItem
            isActive={pathname === '#'}
            onClick={onLogOut}
            open={userToggle}
            isFixed={isOpen}
            title="Log out"
            layout="pils"
            type="child"
            addFunction
            icon="lockclosed"
            url="#"
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            isActive={pathname === '/pages'}
            isFixed={isOpen}
            icon="page"
            title="Pages"
            layout="pils"
            url="/pages"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/menus'}
            isFixed={isOpen}
            icon="list"
            title="Menus"
            layout="pils"
            url="/menus"
            type="child"
            open
          />
          <SidebarItem
            url="https://cloudinary.com/console/media_library/folders/all/"
            title="Media Library"
            isFixed={isOpen}
            isActive={false}
            layout="pils"
            type="child"
            icon="photo"
            open
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            isActive={pathname === '/ws-category'}
            title="Categories"
            url="/ws-categories"
            icon="servers"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/ws-products'}
            title="Products"
            url="/ws-products"
            icon="store"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/ws-orders'}
            title="Orders"
            url="/ws-orders"
            icon="shopping"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            isActive={pathname === '/forms'}
            title="Forms"
            url="/forms"
            icon="checkmark"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/form-submissions'}
            title="Submissions"
            url="/form-submissions"
            icon="mail"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            onClick={() => setSettingsToggle(!settingsToggle)}
            // isActive={isActiveSettings}
            open={settingsToggle}
            isFixed={isOpen}
            title="Settings"
            layout="pills"
            type="parent"
            icon="settings"
          />
          <SidebarItem
            isActive={pathname === '/dictionary'}
            open={settingsToggle}
            title="Dictionary"
            url="/dictionary"
            isFixed={isOpen}
            icon="translate"
            layout="pils"
            type="child"
          />
          <SidebarItem
            isActive={pathname === '/general'}
            title="General Settings"
            open={settingsToggle}
            isFixed={isOpen}
            url="/general/en"
            layout="pils"
            type="child"
            icon="cog"
          />
        </SidebarContainer>
      </SidebarWrapper>
    </SidebarStyles>
  )
}

// const mapStateToProps = state => ({ campaignlist: state.modules.campaign.sidebar.data })
// export default connect(mapStateToProps, { fetchCampaignList })(Sidebar)
export default Sidebar
