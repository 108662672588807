/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useContext } from 'react'
import { isEqual } from 'lodash'
import { IInputProps } from '../../form.provider'
import { FlexItem, Button } from '../../../layout'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import PopupContext from '../../../contexts/popup.context'
import { SectionHeader } from '../../../layout/section/section.styles'
import DarkModeContext from '../../../contexts/darkmode.context'

interface IProps extends IInputProps {
  id: string
  form?: string
  fullWidth?: boolean
}

const NestedFormField: React.FC<IProps> = props => {
  const popupCtx = useContext(PopupContext)
  const { dark } = useContext(DarkModeContext)
  const [snapshot, setSnapshot] = useState()
  const [newValue, setNewValue] = useState()
  const { setValue, value, fieldInfo, form, id, providedVars } = props
  const { name, config } = fieldInfo
  const { flex, label, header, uniqueID } = config

  useEffect(() => {
    if (!isEqual(snapshot, value)) setSnapshot(value)
    if (!isEqual(newValue, value)) setNewValue(value)
  }, [value, form])

  useEffect(() => {
    if ((newValue || newValue === '') && !isEqual(newValue, value)) {
      setValue({ [name as string]: newValue })
    }
  }, [newValue])

  if (!form) return <div style={{ color: 'red' }}>Valid form name is required!</div>

  const onSubmit = (v: any) => {
    setSnapshot(v)
    setNewValue(v)
  }

  const onClickCreate = () => {
    popupCtx.show({
      id: id || (uniqueID as string),
      type: FORM_POPUP,
      options: {
        schema: form,
        data: snapshot,
        variables: providedVars,
      },
      submit: (cbdata: any) => onSubmit(cbdata),
    })
  }

  return (
    <FlexItem flex="100%">
      {header && <SectionHeader dark={dark}>{header}</SectionHeader>}
      <FlexItem flex={flex || '100%'} alignItems="start">
        <Button fullWidth ghost type="button" onClick={onClickCreate}>
          {label}
        </Button>
      </FlexItem>
    </FlexItem>
  )
}

export default NestedFormField
