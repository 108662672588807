/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_TRANSLATION_MUTATION = gql`
  mutation CreateTranslation($data: TranslationCreateInput!) {
    createTranslation(data: $data) {
      id
      locale
      key
      value
      created_at
      updated_at
    }
  }
`

export const UPDATE_TRANSLATION_MUTATION = gql`
  mutation UpdateTranslation($id: ObjectID!, $data: TranslationUpdateInput!) {
    updateTranslation(where: { id: $id }, data: $data) {
      id
      locale
      key
      value
    }
  }
`

export const DELETE_TRANSLATION_MUTATION = gql`
  mutation DeleteTranslation($id: ObjectID!) {
    deleteTranslation(where: { id: $id }) {
      id
    }
  }
`
