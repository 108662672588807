import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import rem from '../../helpers/rem.helper'
import { colors, fonts } from '../variables'
import { MAIN_BRAND_COLOR, BUTTON_BORDER_RADIUS } from '../../config/layout.config'

export interface IButton {
  secondaryLight?: boolean
  secondary?: boolean
  disabled?: boolean
  warning?: boolean
  fixer?: boolean
  ghost?: boolean
  to?: string
  fullWidth?: boolean
  absoluteTopRight?: boolean
}

export const Button = styled('button')<IButton>`
  transition: box-shadow, 0.2s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0);
  border-radius: ${BUTTON_BORDER_RADIUS};
  background-color: ${MAIN_BRAND_COLOR};
  border: 1px solid ${MAIN_BRAND_COLOR};
  color: ${colors.white};
  padding: 11px 20px;
  margin-right: 10px;
  min-width: 100px;
  font-weight: 200;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  margin-top: 0;

  ${({ fullWidth }) =>
    fullWidth &&
    `
    min-width: 100%;
  `};

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
    text-decoration: none;
  }

  ${({ warning }) =>
    warning &&
    `
    background-color: ${colors.error};
    border: 1px solid ${colors.error};
    color: ${colors.white};
  `};

  ${({ secondary }) =>
    secondary &&
    `
    background-color: ${colors.darkMidGrey};
    border: 1px solid ${colors.darkMidGrey};
    color: ${colors.white};
  `};

  ${({ secondaryLight }) =>
    secondaryLight &&
    `
background-color: ${colors.lightMidGrey};
border: 1px solid ${colors.lightMidGrey};
color: ${colors.white};
`};

  ${({ ghost }) =>
    ghost &&
    `
    background-color: transparent;
    color: ${MAIN_BRAND_COLOR};
  `};

  ${({ secondary, ghost }) =>
    secondary &&
    ghost &&
    `
    background-color: transparent;
    border: 1px solid ${colors.darkMidGrey};
    color: ${colors.darkMidGrey};
  `};

  ${({ secondaryLight, ghost }) =>
    secondaryLight &&
    ghost &&
    `
    background-color: transparent;
    border: 1px solid ${colors.lightMidGrey};
    color: ${colors.lightMidGrey};
  `};

  ${({ warning, ghost }) =>
    warning &&
    ghost &&
    `
    background-color: transparent;
    border: 1px solid ${colors.error};
    color: ${colors.error};
  `};

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.6;
  `};

  ${({ fixer }) =>
    fixer &&
    `
    border: 1px solid rgba(255, 255, 255, 0.78);
    color: rgba(255, 255, 255, 0.78);
    background-color: transparent;
    border-radius: ${rem(50)};
    margin-top: ${rem(10)};
    height: ${rem(40)};
    width: ${rem(40)};
    margin-right: 0;
    outline: none;
    min-width: 0;
    padding: 0;
  `};
`

export const ButtonLink = styled(Link)<IButton>`
  transition: box-shadow, 0.2s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0);
  border-radius: ${BUTTON_BORDER_RADIUS};
  background-color: ${MAIN_BRAND_COLOR};
  border: 1px solid ${MAIN_BRAND_COLOR};
  color: ${colors.white};
  padding: 11px 20px;
  margin-right: 10px;
  min-width: 100px;
  font-weight: 200;
  font-family: ${fonts.osc};
  font-size: 13px;
  cursor: pointer;
  outline: none;
  margin-top: 0;
  text-decoration: none;

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
    text-decoration: none;
  }

  ${({ warning }) =>
    warning &&
    `
  background-color: ${colors.error};
  border: 1px solid ${colors.error};
  color: ${colors.white};
`};

  ${({ secondary }) =>
    secondary &&
    `
  background-color: ${colors.darkMidGrey};
  border: 1px solid ${colors.darkMidGrey};
  color: ${colors.white};
`};

  ${({ secondaryLight }) =>
    secondaryLight &&
    `
background-color: ${colors.lightMidGrey};
border: 1px solid ${colors.lightMidGrey};
color: ${colors.white};
`};

  ${({ ghost }) =>
    ghost &&
    `
  background-color: transparent;
  color: ${MAIN_BRAND_COLOR};
`};

  ${({ secondary, ghost }) =>
    secondary &&
    ghost &&
    `
  background-color: transparent;
  border: 1px solid ${colors.darkMidGrey};
  color: ${colors.darkMidGrey};
`};

  ${({ secondaryLight, ghost }) =>
    secondaryLight &&
    ghost &&
    `
  background-color: transparent;
  border: 1px solid ${colors.lightMidGrey};
  color: ${colors.lightMidGrey};
`};

  ${({ warning, ghost }) =>
    warning &&
    ghost &&
    `
  background-color: transparent;
  border: 1px solid ${colors.error};
  color: ${colors.error};
`};

  ${({ disabled }) =>
    disabled &&
    `
  cursor: not-allowed;
  opacity: 0.6;
`};

  ${({ absoluteTopRight }) =>
    absoluteTopRight &&
    `
    position: absolute;
    top: 32px;
    right: 15px;
    text-align: center;
`};

  ${({ fixer }) =>
    fixer &&
    `
  border: 1px solid rgba(255, 255, 255, 0.78);
  color: rgba(255, 255, 255, 0.78);
  background-color: transparent;
  border-radius: ${rem(50)};
  margin-top: ${rem(10)};
  height: ${rem(40)};
  width: ${rem(40)};
  margin-right: 0;
  outline: none;
  min-width: 0;
  padding: 0;
`};
`
