/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const WS_PRODUCT_QUERY = gql`
  query WsProduct($slug: String!) {
    wsProduct(where: { slug: $slug }) {
      id
      wsid
      status
      name
      slug
      position
      brand
      description
      seo {
        title
        image
        keywords
        description
      }
      details {
        name
        modules {
          id
          type
          info
          data
        }
      }
      price
      tax
      discount
      variations {
        id
        name
        translation_key
        options {
          id
          name
          image
          price
          translation_key
        }
      }
      featured_image
      thumbnail_image
      gallery {
        url
        alt
        variation_key
      }
      category {
        wsid
        name
      }
      featured_products {
        wsid
        name
      }
    }

    wsCategories(where: { status: ACTIVE }) {
      id
      wsid
      name
    }
    wsProducts(where: { status: ACTIVE }, limit: 100) {
      id
      wsid
      name
    }
  }
`

export const WS_PRODUCTS_QUERY = gql`
  query WsProducts(
    $locale: Locale
    $search: String
    $orderBy: WsProductOrderByInput
    $skip: Int
    $limit: Int
    $status: Status
    $category: String
  ) {
    wsProducts(
      where: { locale: $locale, status: $status, category: $category }
      searchInNameAndSlugAndBrand: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      wsid
      status
      name
      slug
      position
      brand
      price
      discount
      tax
      category {
        name
      }
      updated_at
    }
    wsProductCount(where: { locale: $locale, status: $status }, searchInNameAndSlugAndBrand: $search)

    wsCategories(where: { status: ACTIVE }) {
      wsid
      name
    }
  }
`
