/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const MENU_QUERY = gql`
  query Menu($slug: String!, $locale: Locale!) {
    menu(where: { slug: $slug, locale: $locale }) {
      id
      locale
      status
      name
      slug
      links {
        id
        type
        name
        url
      }
    }
  }
`

export const MENUS_QUERY = gql`
  query Menus($locale: Locale, $search: String, $orderBy: MenuOrderByInput, $skip: Int, $limit: Int, $status: Status) {
    menus(
      where: { locale: $locale, status: $status }
      searchInNameAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      locale
      status
      name
      slug
      updated_at
    }

    menuCount(where: { locale: $locale, status: $status }, searchInNameAndSlug: $search)
  }
`
