import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation, useLazyQuery } from 'react-apollo'
import { useParams } from 'react-router'
import { IWsCategory } from '../../../interfaces/ws-category/ws-category.interface'
import { dataFormatter } from '../../../helpers/data-formatter'
import { WS_CATEGORY_QUERY } from '../../../graphql/ws-category/queries/ws-category'
import {
  UPDATE_WS_CATEGORY_MUTATION,
  DELETE_WS_CATEGORY_MUTATION,
} from '../../../graphql/ws-category/mutations/ws-category'
import FormProvider from '../../../form2/form.provider'
import { WS_CATEGORY_UPDATE_SCHEMA, WS_CATEGORY_TRANSLATION_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { STATUS_OPTIONS, LOCALE_OPTIONS } from '../../../config/select-options.config'
import NotificationContext from '../../../contexts/notification.context'
import { PageHeader, FlexItem, Grid, Button, ButtonLink } from '../../../layout'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { LOCALES, DEFAULT_LOCALE } from '../../../config/variables.config'
import { WS_CATEGORY_TRANSLATIONS_QUERY } from '../../../graphql/ws-category-translation/queries/ws-category-translation'
import {
  CREATE_WS_CATEGORY_TRANSLATION_MUTATION,
  UPDATE_WS_CATEGORY_TRANSLATION_MUTATION,
} from '../../../graphql/ws-category-translation/mutations/ws-category-translation'
import PopupContext from '../../../contexts/popup.context'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { SectionWrapper, SectionHeader, SectionContent } from '../../../layout/section/section.styles'
import DarkModeContext from '../../../contexts/darkmode.context'
import { IWsCategoryTranslation } from '../../../interfaces/ws-category-translation/ws-category-translation.interface'

const WsCategoryDetailsPage: React.FC = () => {
  const { slug: qSlug } = useParams() as { locale: string; slug: string }
  const [wsCategoryID, setWsCategoryID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IWsCategory>()
  const notificationCtx = useContext(NotificationContext)
  const popupCtx = useContext(PopupContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx

  /**
   * QUERIES
   */

  // ws-category-translation
  const [getTranslations, { data: translationsData }] = useLazyQuery(WS_CATEGORY_TRANSLATIONS_QUERY)

  // ws-category
  const { error: queryError, data: queryData } = useQuery(WS_CATEGORY_QUERY, {
    variables: { slug: qSlug },
  })
  useEffect(() => {
    if (queryData && queryData.wsCategory) {
      const { wsCategory } = queryData
      const fd = dataFormatter(wsCategory, {})
      const { id, ...formattedData } = fd
      setWsCategoryID(id)
      setSnapshot(formattedData as IWsCategory)
      getTranslations({ variables: { wsid: wsCategory.wsid } })
    }
  }, [queryData])

  /**
   * MUTATIONS
   */

  // create  ws-category-translation
  const [
    createWsCategoryTranslation,
    { error: createWsCategoryTranslationError, data: createWsCategoryTranslationData },
  ] = useMutation(CREATE_WS_CATEGORY_TRANSLATION_MUTATION)
  useEffect(() => {
    if (
      !createWsCategoryTranslationError &&
      createWsCategoryTranslationData &&
      createWsCategoryTranslationData.createWsCategoryTranslation
    ) {
      window.location.href = `ws-category-translation/${createWsCategoryTranslationData.createWsCategoryTranslation.wsid}/${createWsCategoryTranslationData.createWsCategoryTranslation.locale}`
    }
  }, [createWsCategoryTranslationData])

  // update ws-category-translation
  const [updateWsCategoryTranslation] = useMutation(UPDATE_WS_CATEGORY_TRANSLATION_MUTATION)

  // update ws-category
  const [updateWsCategory, { error: mutationError, data: mutationData }] = useMutation(UPDATE_WS_CATEGORY_MUTATION)
  const [deleteWsCategory, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_WS_CATEGORY_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateWsCategory) {
      const { updateWsCategory: updateWsCategoryData } = mutationData
      const fd = dataFormatter(updateWsCategoryData, {})
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData as IWsCategory)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Category Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting category!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Category Deleted!' })
      setTimeout(() => {
        window.location.href = `/ws-categories`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * POPUPS
   */
  const onClickCreateTranslation = (locale: string) => {
    const initialData = {
      wsid: snapShot ? snapShot.wsid : '',
      locale,
      seo: { create: {} },
    }

    popupCtx.show({
      id: 'create-ws-category-translation',
      type: FORM_POPUP,
      options: {
        schema: WS_CATEGORY_TRANSLATION_CREATE_SCHEMA,
        data: initialData,
        variables: { LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createWsCategoryTranslation({ variables: { data: cbdata } }),
    })
  }

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }

    // modify wsProductTranslations wsid
    if (snapShot && data && snapShot.wsid !== data.wsid) {
      if (
        translationsData &&
        translationsData.wsCategoryTranslations &&
        translationsData.wsCategoryTranslations.length
      ) {
        const { wsCategoryTranslations } = translationsData as { wsCategoryTranslations: IWsCategoryTranslation[] }
        wsCategoryTranslations.forEach(translationItem => {
          const { id: tID, locale: tLocale, name: tName, slug: tSlug } = translationItem
          updateWsCategoryTranslation({
            variables: { id: tID, data: { locale: tLocale, name: tName, slug: tSlug, wsid: data.wsid } },
          })
        })
      }
    }

    updateWsCategory({ variables: { id: wsCategoryID, data: newData } })
  }

  const onDelete = () => {
    deleteWsCategory({ variables: { id: wsCategoryID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { wsCategory } = queryData
  const { name, slug } = wsCategory as IWsCategory

  return (
    <div>
      <PageHeader title={`Category Details - ${name}`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onDelete={onDelete}
            onSubmit={onUpdate}
            deleteVerifier={slug}
            schema={WS_CATEGORY_UPDATE_SCHEMA}
            variables={{ STATUS_OPTIONS }}
            data={snapShot}
          />
          <Grid marginTop="30px">
            <SectionWrapper>
              <SectionHeader dark={dark}>Translations</SectionHeader>
              <SectionContent>
                <Table>
                  <Thead>
                    <Th>Locale</Th>
                    <Th textAlign="right">Actions</Th>
                  </Thead>
                  <Tbody>
                    {!!LOCALES.length &&
                      LOCALES.map(locale => {
                        if (locale === DEFAULT_LOCALE) return null
                        let trasnlations = []
                        if (translationsData && translationsData.wsCategoryTranslations)
                          trasnlations = translationsData.wsCategoryTranslations
                        const isExist = trasnlations.find(({ locale: l }: any) => l === locale)

                        return (
                          <Tr key={locale}>
                            <Td>{locale}</Td>
                            {!isExist && <Button onClick={() => onClickCreateTranslation(locale)}>ADD</Button>}
                            {isExist && (
                              <Td textAlign="right">
                                <ButtonLink ghost to={`/ws-category-translation/${snapShot.wsid}/${locale}`}>
                                  EDIT
                                </ButtonLink>
                              </Td>
                            )}
                          </Tr>
                        )
                      })}
                  </Tbody>
                </Table>
              </SectionContent>
            </SectionWrapper>
          </Grid>
        </FlexItem>
      )}
    </div>
  )
}

export default WsCategoryDetailsPage
