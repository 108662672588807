import styled from 'styled-components/macro'
import { colors } from '../variables'

export const AppFrame = styled('div')<{ dark?: boolean }>`
  background-color: ${({ dark }) => (dark ? colors.midGrey : colors.bodyLight)};
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
`

export const Content = styled('div')<{ isOpen: boolean; dark?: boolean }>`
  background-color: ${({ dark }) => (dark ? colors.midGrey : colors.bodyLight)};
  margin: 0;
  box-sizing: border-box;
  transition: margin-left 0.3s ease-in-out;
  margin-left: ${({ isOpen }) => (isOpen ? '260px' : '80px')};
  position: relative;
  width: auto;
`
