/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro'
import rem from '../../helpers/rem.helper'

export const FooterStyle = styled.div`
  padding: 0px ${rem(32)};
  text-align: right;
  position: absolute;
  bottom: 0;
  color: #aaa;
  right: 0;
`
