export const WINDOW_BORDER_RADIUS = '3px'
export const BUTTON_BORDER_RADIUS = '4px'
export const INPUT_BORDER_RADIUS = '4px'

export const MAIN_BRAND_COLOR = 'rgba(241, 155, 57, 1)'
export const SECONDARY_BRAND_COLOR = ''

export const DARK_MODE = true

export const BRAND_IMAGE = ''
export const LOGIN_BRAND_IMAGE = 'https://res.cloudinary.com/nahov91/image/upload/v1579875900/Black%20DOME/login_bg.jpg'
export const BRAND_LOGO = 'https://res.cloudinary.com/nahov91/image/upload/v1579875899/Black%20DOME/aery_logo_icon.png'
