import { gql } from 'apollo-boost'

export const CREATE_WS_PRODUCT_TRANSLATION_MUTATION = gql`
  mutation CreateWsProductTranslation($data: WsProductTranslationCreateInput!) {
    createWsProductTranslation(data: $data) {
      id
      wsid
      locale
      name
      slug
    }
  }
`

export const CLONE_WS_PRODUCT_TRANSLATION_MUTATION = gql`
  mutation CloneWsProductTranslation($id: ObjectID!, $tag: String) {
    cloneWsProductTranslation(where: { id: $id }, tag: $tag) {
      id
      wsid
      name
      slug
    }
  }
`

export const UPDATE_WS_PRODUCT_TRANSLATION_MUTATION = gql`
  mutation UpdateWsProductTranslation($id: ObjectID!, $data: WsProductTranslationUpdateInput!) {
    updateWsProductTranslation(where: { id: $id }, data: $data) {
      id
      wsid
      locale
      name
      slug
      description
      seo {
        title
        description
        keywords
        image
      }
      details {
        name
        modules {
          id
          type
          info
          data
        }
      }
      keys {
        key
        value
      }
    }
  }
`
