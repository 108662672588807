import React, { useEffect, useState, useContext } from 'react'
import NotificationContext from '../../contexts/notification.context'

const ErrorHandler: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const [errs, setErrs] = useState<string[]>([])

  // Set a watcher in wondow.GQL_ERRORS
  // if the value is changed display and reset it
  useEffect(() => {
    Object.defineProperty(window, 'GQL_ERRORS', {
      get: () => errs,
      set: v => setErrs(v),
    })
  }, [])

  useEffect(() => {
    if (errs.length) {
      notificationCtx.show({ type: 'error', icon: 'error', message: errs.join(' ') })
      setErrs([])
    }
  }, [errs])

  return null
}

export default ErrorHandler
