import { gql } from 'apollo-boost'

export const FORM_SUBMISSION_QUERY = gql`
  query FormSubmission($id: ObjectID!) {
    formSubmission(where: { id: $id }) {
      id
      locale
      status
      email
      form {
        id
        alias
      }
      data {
        name
        value
      }
      notes
    }

    forms {
      id
      alias
    }
  }
`

export const FORM_SUBMISSIONS_QUERY = gql`
  query FormSubmissions(
    $locale: Locale
    $status: FormSubmissionStatus
    $email: String
    $form: ObjectID
    $orderBy: FormSubmissionOrderByInput
    $skip: Int
    $limit: Int
  ) {
    formSubmissions(
      where: { locale: $locale, status: $status, email: $email, form: $form }
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      locale
      status
      email
      form {
        id
        alias
      }
      data {
        name
        value
      }
      notes
      created_at
    }
    formSubmissionCount(where: { locale: $locale, status: $status, email: $email, form: $form })
    forms {
      id
      alias
    }
  }
`
