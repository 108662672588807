import { gql } from 'apollo-boost'

export const UPDATE_FORM_SUBMISSION_MUTATION = gql`
  mutation UpdateFormSubmission($id: ObjectID!, $data: FormSubmissionUpdateInput!) {
    updateFormSubmission(where: { id: $id }, data: $data) {
      id
      locale
      status
      email
      form {
        id
        alias
      }
      data {
        name
        value
      }
      notes
    }
  }
`

export const DELETE_FORM_SUBMISSION_MUTATION = gql`
  mutation DeleteFormSubmission($id: ObjectID!) {
    deleteFormSubmission(where: { id: $id }) {
      id
    }
  }
`
