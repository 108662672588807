// Default module datas
const HeroModule = {
  title: '',
  description: '',
  backgroundImage: '',
}

const HeadingModule = {
  title: '',
  titleType: 'h1',
  subtitle: '',
  subtitleType: 'h3',
}

const TextModule = {
  content: '',
}

const TextAndImageModule = {
  title: '',
  content: '',
  image: {
    url: '',
    alt: '',
  },
  reverse: false,
}

const FullWidthImageModule = {
  url: '',
  alt: '',
}

const FeaturedTilesModule = {
  main: {
    dots: true,
    infinite: true,
    speed: 1000,
    slides: {
      create: [
        // { title: '', subtitle: '', content1: '', content2: '', image: '', button: { url: '', icon: '', text: '' } },
      ],
    },
  },
  sideTop: {
    title: '',
    subtitle: '',
    image: '',
    button: {
      url: '',
      icon: '',
      text: '',
    },
  },
  sideBottom: {
    title: '',
    subtitle: '',
    image: '',
    button: {
      url: '',
      icon: '',
      text: '',
    },
  },
}

const BenefitsModule = {
  items: [
    // { icon: '', highlightedText: '', text: '' },
  ],
}

const IconsModule = {
  align: 'center',
  items: [],
}

const SpecificationsModule = {
  items: [],
}

const VideoModule = {
  tag: '',
  title: '',
  subtitle: '',
  link: '',
  viewMoreText: '',
  viewMoreLink: '',
  image: '',
}

const FormModule = {
  formId: '',
}

const FeaturedProductsModule = {
  title: '',
  column: '',
  products: [],
}

const FeaturedCategoriesModule = {
  title: '',
  column: '',
  categories: [],
}

const ProductHighlightModule = {
  title: '',
  subtitle: '',
  button: {
    url: '',
    icon: '',
    text: '',
  },
  image: '',
}

// default info datas
const Info = {
  marginTop: '',
  marginBottom: '',
  paddingTop: '',
  paddingBottom: '',
}

export interface IModuleList {
  HeroModule: string
  HeadingModule: string
  TextModule: string
  TextAndImageModule: string
  FullWidthImageModule: string
  FeaturedTilesModule: string
  BenefitsModule: string
  IconsModule: string
  VideoModule: string
  FormModule: string
  FeaturedProductsModule: string
  FeaturedCategoriesModule: string
  ProductHighlightModule: string
  SpecificationsModule: string
  Info: string
  [key: string]: string
}

export const MODULE_TYPE_OPTIONS = [
  { label: 'Hero', value: 'HeroModule' },
  { label: 'Heading', value: 'HeadingModule' },
  { label: 'Text', value: 'TextModule' },
  { label: 'Text and Image', value: 'TextAndImageModule' },
  { label: 'Full Width Image', value: 'FullWidthImageModule' },
  { label: 'Featured Tiles', value: 'FeaturedTilesModule' },
  { label: 'Benefits', value: 'BenefitsModule' },
  { label: 'Product Spec. Icons', value: 'IconsModule' },
  { label: 'Video', value: 'VideoModule' },
  { label: 'Form', value: 'FormModule' },
  { label: 'Featured Products', value: 'FeaturedProductsModule' },
  { label: 'Featured Categories', value: 'FeaturedCategoriesModule' },
  { label: 'Product Highlight', value: 'ProductHighlightModule' },
  { label: 'Product Specifications', value: 'SpecificationsModule' },
]

export default {
  Info,
  HeroModule,
  HeadingModule,
  TextModule,
  TextAndImageModule,
  FullWidthImageModule,
  FeaturedTilesModule,
  BenefitsModule,
  IconsModule,
  VideoModule,
  SpecificationsModule,

  FormModule,
  FeaturedProductsModule,
  FeaturedCategoriesModule,
  ProductHighlightModule,
}
