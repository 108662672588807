/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_WS_CATEGORY_TRANSLATION_MUTATION = gql`
  mutation CreateWsCategoryTranslation($data: WsCategoryTranslationCreateInput!) {
    createWsCategoryTranslation(data: $data) {
      id
      locale
      wsid
      name
      slug
      description
      seo {
        title
        description
        keywords
        image
      }
    }
  }
`

export const UPDATE_WS_CATEGORY_TRANSLATION_MUTATION = gql`
  mutation UpdateWsCategoryTranslation($id: ObjectID!, $data: WsCategoryTranslationUpdateInput!) {
    updateWsCategoryTranslation(where: { id: $id }, data: $data) {
      id
      locale
      wsid
      name
      slug
      description
      seo {
        title
        description
        keywords
        image
      }
    }
  }
`
