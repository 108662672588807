import FormPopup from './types/form'
import DeletePopup from './types/delete'
import ConfirmPopup from './types/confirm'

export const FORM_POPUP = 'FormPopup'
export const DELETE_POPUP = 'DeletePopup'
export const CONFIRM_POPUP = 'ConfirmPopup'

export interface IPopupList {
  FormPopup: string
  DeletePopup: string
  [key: string]: string
}

export default {
  FormPopup,
  DeletePopup,
  ConfirmPopup,
}
