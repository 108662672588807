/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react'
import { Grid, Heading } from '../../layout'
import { SectionHeader } from '../../layout/section/section.styles'
import rem from '../../helpers'
import DarkModeContext from '../../contexts/darkmode.context'
import { colors } from '../variables'
import { MAIN_BRAND_COLOR } from '../../config/layout.config'

interface IPageHeader {
  title: string
  locale?: string
  withBackground?: boolean
}

const PageHeader = ({ title, locale, withBackground }: IPageHeader) => {
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  return (
    <Grid
      padding={`0 ${rem(10)} ${rem(75)} ${rem(25)}`}
      backgroundColor={withBackground ? (dark ? colors.almostBlack : '#c5c5c5a3') : 'transparent'}
    >
      <SectionHeader page>
        <Heading as="h4" page textColor={MAIN_BRAND_COLOR}>
          {title} {locale}
        </Heading>
      </SectionHeader>
    </Grid>
  )
}

export default PageHeader
