import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery, useMutation } from 'react-apollo'
import PopupContext from '../../../contexts/popup.context'
import NotificationContext from '../../../contexts/notification.context'
import { removeFalsy } from '../../../helpers/object.helper'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { PageHeader, FlexItem, Grid, Button } from '../../../layout'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { FilterSearch, FilterDropdown, Pagination } from '../../../components'
import { STATUS_OPTIONS, TAX_OPTIONS, WS_PRODUCTS_ORDER_BY_OPTIONS } from '../../../config/select-options.config'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { WS_PRODUCTS_QUERY } from '../../../graphql/ws-product/queries/ws-product'
import { IWsProduct } from '../../../interfaces/ws-product/ws-product.interface'
import { CREATE_WS_PRODUCT_TRANSLATION_MUTATION } from '../../../graphql/ws-product-translation/mutations/ws-product-translation'
import { CREATE_WS_PRODUCT_MUTATION } from '../../../graphql/ws-product/mutations/ws-product'
import { LOCALES, DEFAULT_LOCALE, DEFAULT_STATUS } from '../../../config/variables.config'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { WS_PRODUCT_CREATE_SCHEMA } from '../../../schemas/schema.map'
import DarkModeContext from '../../../contexts/darkmode.context'
import CloneWsProduct from '../../../components/clone-ws-product'

export interface IWsProductListQuery {
  is?: string
  wsid?: string
  status?: string
  category?: string
  locale?: string
  search?: string
  orderBy?: string
  skip?: string
  limit?: string
}

export interface ICategoryOptions {
  id?: string
  wsid?: string
  name: string
}

const DEFAULT_LIMIT = 100
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'name_ASC'

const WsProductList = () => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IWsProductListQuery>({ limit: '10' })
  const [wsCategoryOptions, setWsCategoryOptions] = useState<IOption[]>([])

  /**
   * QUERIES
   */
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(WS_PRODUCTS_QUERY, {
    variables: query,
  })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const {
        orderBy = DEFAULT_ORDER_BY,
        limit = DEFAULT_LIMIT,
        skip = DEFAULT_SKIP,
      } = searchQuery as IWsProductListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])

  useEffect(() => {
    if (queryData && queryData.wsCategories && queryData.wsCategories.length) {
      setWsCategoryOptions(
        queryData.wsCategories.map(({ wsid: cWsid, name: cName }: ICategoryOptions) => ({
          label: cName,
          value: cWsid,
        })),
      )
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [createWsProductTranslation] = useMutation(CREATE_WS_PRODUCT_TRANSLATION_MUTATION)
  const [createWsProduct, { error: mutationError, data: mutationData }] = useMutation(CREATE_WS_PRODUCT_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.createWsProduct) {
      const { createWsProduct: createWsProductData } = mutationData

      LOCALES.forEach(locale => {
        if (locale !== DEFAULT_LOCALE) {
          createWsProductTranslation({
            variables: {
              data: {
                wsid: createWsProductData.wsid,
                locale,
                name: createWsProductData.name,
                slug: createWsProductData.slug,
              },
            },
          })
        }
      })

      window.location.href = `ws-product/${createWsProductData.slug}`
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully created!' })
    }
  }, [mutationData])

  /**
   * POPUPS
   */
  const onClickCreate = () => {
    const initialData = {
      status: DEFAULT_STATUS,
      tax: 27,
      discount: 0,
      seo: {
        create: {
          title: '',
        },
      },
    }

    popupCtx.show({
      id: 'create-ws-product',
      type: FORM_POPUP,
      options: {
        schema: WS_PRODUCT_CREATE_SCHEMA,
        data: initialData,
        variables: { STATUS_OPTIONS, TAX_OPTIONS, WS_CATEGORY_OPTIONS: wsCategoryOptions },
      },
      submit: (cbdata: any) => createWsProduct({ variables: { data: cbdata } }),
    })
  }

  /**
   * COMPONENT LOGIC
   */
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { wsProductCount, wsProducts: qWsProducts = [] } =
    queryData || ({} as { wsCategoryCount: number; wsProducts: IWsProduct[] })
  const wsProducts: IWsProduct[] = qWsProducts as IWsProduct[]

  return (
    <div>
      <PageHeader title="Products" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Actions</SectionHeader>
            <Grid mdCol="1fr auto" gap="20px" alignContent="end" justifyContent="start" alignItems="end">
              <FilterSearch
                name="search"
                label="Search (in name and slug and brand)"
                value={query.search}
                onChange={value => pushQuery('search', value)}
              />
              <Button ghost onClick={onClickCreate}>
                Create New Product
              </Button>
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={3} gap="20px">
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={WS_PRODUCTS_ORDER_BY_OPTIONS as [IOption]}
                onChange={value => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={STATUS_OPTIONS as [IOption]}
                onChange={value => pushQuery('status', value)}
              />
              <FilterDropdown
                name="category"
                label="Category"
                value={query.category ? { value: query.category, label: query.category } : undefined}
                options={wsCategoryOptions as [IOption]}
                onChange={value => pushQuery('category', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Name</Th>
                  <Th>Slug</Th>
                  <Th>Brand</Th>
                  <Th>Status</Th>
                  <Th>Wsid</Th>
                  <Th>Price</Th>
                  <Th>Tax</Th>
                  <Th>Category</Th>
                  <Th>Position</Th>
                  <Th>Actions</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!wsProducts.length &&
                    wsProducts.map(({ id, wsid, name, slug, status, price, tax, category, brand, position }) => {
                      return (
                        <Tr key={id}>
                          <Td>
                            <NavLink to={`/ws-product/${slug}`}>{name}</NavLink>
                          </Td>
                          <Td>{slug}</Td>
                          <Td>{brand}</Td>
                          <Td>{status}</Td>
                          <Td>{wsid}</Td>
                          <Td>{price}</Td>
                          <Td>{tax}%</Td>
                          <Td>{category ? category.name : 'uncategorized'}</Td>
                          <Td>{position}</Td>
                          <Td>
                            <CloneWsProduct refreshProductList={getList} id={id} wsid={wsid} />
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={wsProductCount} query={query} pushQuery={pushQuery} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default WsProductList
