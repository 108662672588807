import React from 'react'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { SERVER_URI, AUTH_HEADER } from '../../config/variables.config'

const InitApollo: React.FC<any> = ({ children, token }: { children: any; token: string }) => {
  const httpLink = createHttpLink({ uri: `${SERVER_URI}/query` })
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        [AUTH_HEADER]: token ? `Bearer ${token}` : '',
      },
    }
  })
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const m: string[] = []
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        m.push(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      )
    if (networkError) m.push(`[Network error]: ${networkError}`)
    const w: any = window
    w.GQL_ERRORS = m
  })
  const link = ApolloLink.from([authLink, errorLink, httpLink])

  const defaultOptions: any = {
    watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'ignore' },
    query: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
  }

  const client = new ApolloClient({ link, cache: new InMemoryCache(), defaultOptions })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default InitApollo
