import React from 'react'
import Pagination from 'rc-pagination'
import { PaginationStyle } from './pagination.styles'
import { Grid } from '../../layout'
import FilterDropdown from '../filter-dropdown'
import { getCurrent, getSkip } from '../../helpers/pagination.helper'
import { LIMIT_OPTIONS } from '../../config/select-options.config'
import { IOption } from '../../interfaces/schema/schema.interface'

interface IQuery {
  orderBy?: string
  limit?: number | string
  skip?: number | string
}

interface IPagination {
  query: IQuery
  pushQuery: (key: string, value: string) => void
  totalCount: number
}

const DEFAULT_LIMIT = 10

const PaginationComponent = ({ query, pushQuery, totalCount }: IPagination) => (
  <PaginationStyle>
    <Grid mdCol="auto 100px" gap="20px" marginTop="30px" justifyContent="start" marginBottom="30px">
      <Pagination
        onChange={nextCurrent => pushQuery('skip', getSkip(nextCurrent, query.limit as string))}
        current={getCurrent(query.skip as string, query.limit as string)}
        pageSize={parseInt(query.limit as string, 10)}
        total={totalCount}
      />
      <FilterDropdown
        name="limit"
        disableSelect
        value={{ value: query.limit || DEFAULT_LIMIT, label: query.limit || DEFAULT_LIMIT }}
        options={LIMIT_OPTIONS as [IOption]}
        onChange={value => pushQuery('limit', value)}
      />
    </Grid>
  </PaginationStyle>
)

export default PaginationComponent
