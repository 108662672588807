import queryString, { ParsedUrlQueryInput } from 'querystring'
import { Location, History } from 'history'
import { removeFalsy } from './object.helper'

export const usePushQuery = (location: Location, history: History) => {
  const pushQyeryString = (key: string, value: string | undefined) => {
    const { search: locationSearch, pathname } = location
    const { push } = history

    let filteredValue = value
    if (value === 'select') filteredValue = undefined

    const searchQuery = queryString.parse(locationSearch.substr(1))
    const newQuery = {
      ...removeFalsy(searchQuery),
      ...{ [key]: filteredValue },
      ...(key !== 'skip' && { skip: 0 }),
    }
    const stringifiedQuery = queryString.stringify(newQuery as ParsedUrlQueryInput)
    push(`${pathname}?${stringifiedQuery}`)
  }

  return [pushQyeryString]
}
