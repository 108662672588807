/* eslint-disable indent */
import styled from 'styled-components/macro'
// import { fonts, colors } from '../../layout'

export const CloneButton = styled('button')`
  background: none;
  cursor: pointer;
  outline: none;
  border: 0px;
`
