/* eslint-disable indent */
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import rem from '../../helpers/rem.helper'
import { colors } from '../../layout'
import { fonts } from '../variables'
import { MAIN_BRAND_COLOR } from '../../config/layout.config'

export const TableResponsive = styled.div`
  display: block;
  width: 100%;
`

export const Table = styled.table`
  font-family: ${fonts.osc};
  background-color: transparent;
  margin-bottom: 1rem;
  border-radius: 5px;
  width: 100%;
`

export const Thead = styled.thead`
  font-family: ${fonts.osc};
  color: ${MAIN_BRAND_COLOR};
`

export const Tbody = styled.tbody`
  /* & tr:hover {
    background-color: rgb(247, 247, 247);
  } */
`

export const Tr = styled('tr')<{ cursor?: string; statusNew?: boolean }>`
  ${({ cursor }) =>
    cursor &&
    `
    cursor: ${cursor};
  `}
  height: 3.5rem;
  vertical-align: middle;
  & button {
    /* opacity: 0; */
  }
  &:hover button {
    opacity: 1;
  }
  :nth-of-type(even) {
    background-color: ${colors.lightGrey};
  }

  a,
  td {
    ${({ statusNew }) =>
      statusNew &&
      `
        font-weight: 500;
    `}
  }
`
export const Th = styled('th')<{ textAlign?: string }>`
  border-bottom: 1px solid #dee2e6;
  border-top: 0px transparent;
  vertical-align: middle;
  padding-left: 0.5rem;
  text-align: left;
  text-align: ${({ textAlign }) => textAlign && textAlign};
  padding-right: 20px;
  font-size: 1.2rem;
  font-weight: 200;
`

export const Td = styled('td')<{ textAlign?: string }>`
  vertical-align: middle;
  font-size: ${rem(14)};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  padding: ${rem(10)};
  position: relative;
  font-weight: 200;
`

export const TableAction = styled('div')<{ left: boolean }>`
  transition: 0.1s ease-in;
  margin-top: ${rem(7)};
  display: inline-block;
  position: absolute;
  ${({ left }) =>
    left &&
    `
    left: 0;
  `}
  right: 0;
  top: 0;

  & button {
    width: 60px;
  }
  & a {
    width: 60px;
  }
`

export const Slug = styled.span`
  font-size: 10px;
  display: block;
  color: #1794a3;
`

export const NavLink = styled(Link)`
  font-family: ${fonts.osc};
  text-decoration: none;
  color: ${colors.body};
  font-size: ${rem(16)};
  &:hover {
    color: ${MAIN_BRAND_COLOR};
  }
`
export const LinkText = styled.p`
  font-family: ${fonts.osc};
  text-decoration: none;
  color: ${colors.body};
  font-size: ${rem(16)};
  &:hover {
    color: ${MAIN_BRAND_COLOR};
  }
`
