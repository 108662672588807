import { gql } from 'apollo-boost'

export const CREATE_FORM_MUTATION = gql`
  mutation CreateForm($data: FormCreateInput!) {
    createForm(data: $data) {
      id
      locale
      status
      alias
      title
      slug
    }
  }
`

export const UPDATE_FORM_MUTATION = gql`
  mutation UpdateForm($id: ObjectID!, $data: FormUpdateInput!) {
    updateForm(where: { id: $id }, data: $data) {
      id
      locale
      status
      alias
      title
      slug
      description
      fields {
        id
        name
        type
        label
        placeholder
        required
        value
        options {
          value
          label
        }
        limit
        validation
        validation_error
      }
      success_info {
        title
        description
      }
      error_info {
        title
        description
      }
      mailer_info {
        auto_email_to
        auto_email_sending
        auto_feedback_sending
      }
    }
  }
`

export const DELETE_FORM_MUTATION = gql`
  mutation DeleteForm($id: ObjectID!) {
    deleteForm(where: { id: $id }) {
      id
    }
  }
`
