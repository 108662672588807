/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro'

interface IWrapper {
  backgroundColor?: string
  backgroundImage?: string
  paddingBottom?: string
  marginBottom?: string
  paddingTop?: string
  marginTop?: string
  color?: string
}

export const Wrapper = styled('section')<IWrapper>`
  background-image: ${({ backgroundImage }) => backgroundImage && `url('${backgroundImage}')`};
  background-color: ${({ backgroundColor = 'white' }) => backgroundColor && backgroundColor};
  padding-bottom: ${({ paddingBottom = '0' }) => paddingBottom && paddingBottom};
  margin-bottom: ${({ marginBottom = '0' }) => marginBottom && marginBottom};
  padding-top: ${({ paddingTop = '0' }) => paddingTop && paddingTop};
  margin-top: ${({ marginTop = '0' }) => marginTop && marginTop};
  color: ${({ color = '' }) => color && color};
  background-position: center center;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  width: 100%;
`
