/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_WS_CATEGORY_MUTATION = gql`
  mutation CreateWsCategory($data: WsCategoryCreateInput!) {
    createWsCategory(data: $data) {
      id
      status
      wsid
      name
      slug
      featured_image
      thumbnail_image
      position
      description
      seo {
        title
        description
        keywords
        image
      }
    }
  }
`

export const UPDATE_WS_CATEGORY_MUTATION = gql`
  mutation UpdateWsCategory($id: ObjectID!, $data: WsCategoryUpdateInput!) {
    updateWsCategory(where: { id: $id }, data: $data) {
      id
      status
      wsid
      name
      slug
      featured_image
      thumbnail_image
      position
      description
      seo {
        title
        description
        keywords
        image
      }
    }
  }
`

export const DELETE_WS_CATEGORY_MUTATION = gql`
  mutation DeleteWsCategory($id: ObjectID!) {
    deleteWsCategory(where: { id: $id }) {
      id
    }
  }
`
