import { gql } from 'apollo-boost'

export const FORM_QUERY = gql`
  query Form($locale: Locale!, $slug: String!) {
    form(where: { locale: $locale, slug: $slug }) {
      id
      locale
      status
      alias
      title
      slug
      description
      fields {
        id
        name
        type
        label
        placeholder
        required
        value
        options {
          value
          label
        }
        limit
        validation
        validation_error
      }
      success_info {
        title
        description
      }
      error_info {
        title
        description
      }
      mailer_info {
        auto_email_to
        auto_email_sending
        auto_feedback_sending
      }
    }
  }
`

export const FORMS_QUERY = gql`
  query Forms($locale: Locale, $search: String, $orderBy: FormOrderByInput, $skip: Int, $limit: Int, $status: Status) {
    forms(
      where: { locale: $locale, status: $status }
      searchInAliasAndTitleAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      locale
      status
      alias
      title
      slug
      updated_at
    }
    formCount(where: { locale: $locale, status: $status }, searchInAliasAndTitleAndSlug: $search)
  }
`
