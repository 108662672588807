import styled from 'styled-components/macro'
import { colors, fonts } from '../../../../layout'
import rem from '../../../../helpers/rem.helper'

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  color: ${colors.body};
  z-index: 100;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`

export const PopupWrapper = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  border-radius: ${rem(5)};
  margin: ${rem(50)} auto;
  max-width: ${rem(1000)};
  padding: ${rem(50)};
`

export const CloseButton = styled.button`
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: ${rem(20)};
  padding: ${rem(15)};
  position: absolute;
  border: none;
  right: 0;
  top: 0;
  &:hover {
    color: ${colors.main};
  }
`

export const ConfirmationInput = styled('input')<{ valid?: boolean; inValid?: boolean }>`
  border: 1px solid ${colors.lightMidGrey};
  font-family: ${fonts.osc};
  padding: ${rem(10)};
  font-size: 16px;
  outline: none;
  ${({ valid }) =>
    valid &&
    `
    box-shadow: 0 0 2px ${colors.success};
    border: 1px solid ${colors.success};
    `}
  ${({ inValid }) =>
    inValid &&
    `
    box-shadow: 0 0 2px ${colors.error};
    border: 1px solid ${colors.error};
  `}
`
