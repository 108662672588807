import styled from 'styled-components/macro'
import { Link, NavLink } from 'react-router-dom'
import rem from '../../helpers/rem.helper'
import { fonts, colors } from '../../layout'

export interface ISidebarItemStyles {
  type?: string
  open?: boolean
  isFixed?: boolean
  active?: boolean
  header?: boolean
  pills?: boolean
}

export const SidebarItemStyles = styled('div')<ISidebarItemStyles>`
  font-family: ${fonts.osc};
  align-items: center;
  position: relative;
  margin-top: 5px;
  cursor: pointer;
  display: flex;

  &:first-child {
    margin-top: 6px;
  }

  &:last-child {
    margin-bottom: 6px;
  }

  img {
    transition: all 0.3s ease 0s;
    margin-right: 26px;
    text-align: center;
    margin-left: 4px;
    padding: 6px;
    float: left;
    width: 34px;
    opacity: 1;
  }

  ${({ header }) =>
    header &&
    `
    margin-top: 0px;
    cursor: initial;
    height: 70px;
    &:hover {
      background-color: initial;
    }
  `};

  ${({ type, open, isFixed }) =>
    type === 'parent' &&
    `
    &:after {
      font-family: 'Aery-labs-dashboard';
      transform: ${open && 'rotate(180deg)'};
      opacity: ${isFixed ? '1' : '0'};
      transition: all .3s ease 0s;
      position: absolute;
      content: "\\E90d";
      top: ${rem(20)};
      font-size: 10px;
      right: 13px;
    }
  `};

  ${({ type, open }) =>
    type === 'child' &&
    `
    height: ${open ? '45px' : '0'};
    margin: ${open ? '4px 0' : '0'};
    boreder: 1px solid ${open ? 'pink' : 'red'};
    transition: all .3s ease 0s;
    overflow: hidden;
  `};

  ${({ pills }) =>
    pills &&
    `
    border-radius: 50px;
    &:hover {
      background-color: #ffffff2e;
      color: initial;
    }
  `};
  & .active {
    background-color: ${colors.white};
    color: ${colors.darkGrey};
  }
  & .active > div > span:after {
    background-color: ${colors.white};
    color: ${colors.darkGrey};
  }
`

export const SidebarItemPlaceholder = styled.div`
  margin-right: 60px;
  margin-left: 10px;
  height: 40px;
  width: 40px;
`

export const SidebarItemLink = styled(NavLink)`
  text-decoration: none;
  white-space: nowrap;
  line-height: 30px;
  padding: 0.5rem 0;
  font-weight: 100;
  overflow: hidden;
  display: block;
  font-size: ${rem(13)};
  width: 100%;
  color: #fff;
  opacity: 1;
  & .active {
    background-color: ${colors.white};
  }
`

export const SidebarItemTitle = styled.a`
  text-decoration: none;
  white-space: nowrap;
  line-height: 30px;
  padding: 0.5rem 0;
  font-weight: 100;
  overflow: hidden;
  display: block;
  font-size: ${rem(13)};
  width: 100%;
  color: #fff;
`

export const SidebarStyles = styled('div')<{ fixed: boolean; dark?: boolean; backgroundImage: string }>`
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2), 0 2px 30px 0 rgba(0, 0, 0, 0.35);
  background-color: ${({ dark }) => (dark ? colors.darkGrey : colors.darkMidGrey)};
  ${({ backgroundImage }) =>
    backgroundImage !== '' &&
    `
    background-image: url(${backgroundImage});
  `}

  transition-timing-function: linear, linear, ease;
  transition-property: top, bottom, width;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-overflow-scrolling: touch;

  background-size: cover;
  position: fixed;
  display: block;
  z-index: 10030;
  color: white;
  height: 100%;
  height: 100%;
  width: 80px;
  bottom: 0;
  left: 0;
  top: 0;

  ${({ fixed }) =>
    fixed &&
    `
    width: 260px;
    & * {
      opacity: 1;
    }
  `};
  &:hover {
    width: 260px;
    &:hover * {
      opacity: 1;
    }
    &:hover * :after {
      opacity: 1;
    }
  }
`

export const SidebarWrapper = styled.div`
  &:-webkit-scrollbar {
    display: none;
  }
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: relative;
  overflow: scroll;
  height: 100%;
`

export const SidebarContainer = styled('section')<{ bottomLine?: boolean; user?: boolean }>`
  ${({ bottomLine }) =>
    bottomLine &&
    `
  border-bottom: 1px solid hsla(0,0%,100%,.5);
  width: calc(100% - 30px);
  margin: auto;
  `};
`

export const SidebarFixer = styled('div')<{ fixed: boolean }>`
  opacity: ${({ fixed }) => (fixed ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  transition: all 0.3s ease 0s;
  position: absolute;
  top: 5px;
  right: 0;
`

export const HomeLink = styled(Link)<{ visible?: boolean }>`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  font-family: ${fonts.osc};
  word-break: keep-all;
  color: ${colors.white};
  text-decoration: none;
  font-size: 1rem;
`

export const Title = styled('div')<{ visible?: boolean }>`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
`
