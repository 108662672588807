/* eslint-disable import/prefer-default-export */
import { colors, fonts } from '../../../layout'
import rem from '../../../helpers'
import { MAIN_BRAND_COLOR } from '../../../config/layout.config'

// React select style with emotion
export const selectStyle = {
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.18)',
    border: `1px solid ${colors.midGrey}`,
    fontFamily: fonts.osc,
    maxHeight: '150px',
    overflow: 'scroll',
    zIndex: 20,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? `1px solid ${colors.darkGrey}` : `1px solid ${colors.midGrey}`,
    outline: 'none',
    boxShadow: 'none',
    borderRadius: rem(5),
    fontFamily: fonts.osc,
    display: 'flex',
    padding: '1px',
    flex: 1,
    marginBottom: '10px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? MAIN_BRAND_COLOR : null,
    color: colors.body,
    fontFamily: fonts.osc,
    padding: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}
