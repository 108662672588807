import styled from 'styled-components/macro'
import { fonts } from '../variables'

export interface IText {
  error?: boolean
  margin?: string
  medium?: boolean
  textColor?: string
}

const Text = styled('p')<IText>`
  font-family: ${fonts.osc};
  ${({ error }) =>
    error &&
    `
    font-size: 1rem;
    color: #da0c0c;
  `};

  ${({ margin }) =>
    margin &&
    `
    margin: ${margin};
  `};

  ${({ medium }) =>
    medium &&
    `
    font-size: 16px;
  `};

  ${({ textColor }) =>
    textColor &&
    `
    color: ${textColor};
  `};
`

export default Text
