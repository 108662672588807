import React from 'react'

export interface IPopupItem {
  id: string
  type: string
  submit?<T>(data: T): void
  options: {
    schema?: string
    data?: any
    variables?: any
    message?: string
    onDelete?: () => void
    comparedValue?: string
  }
}

export interface IPopupContext {
  show(newItem: IPopupItem): void
}

export const PopupContext = React.createContext<IPopupContext>({
  show: () => {},
})

export default PopupContext
