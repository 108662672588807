import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import rem from '../../helpers/rem.helper'
import { colors, fonts } from '../variables'
import lightenHelper from '../../helpers/lighten.helper'
import { Grid } from '../grid'

export const SwitchGrid = styled(Grid)`
  & > :not(:last-of-type) {
    border-right: 1px solid ${colors.bodyLight};
  }
`

export const Item = styled(Link)<{ active: boolean }>`
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: ${colors.bodyLight};
  font-family: ${fonts.osc};
  line-height: 1.2rem;
  text-decoration: none;
  font-size: 1.2rem;
  min-width: ${rem(40)};
  text-align: center;
  padding: 0 ${rem(7)};
  opacity: 0.8;
  &:hover {
    opacity: 1;
    background-color: ${lightenHelper(colors.midGrey, 5)};
    color: ${lightenHelper(colors.body, 15)};
  }
  ${({ active }) =>
    active &&
    `
    color: ${colors.body};
    &:hover {
      color: ${colors.white};
    }
  `}
`
