import { gql } from 'apollo-boost'

export const WS_ORDER_QUERY = gql`
  query WsOrder($id: ObjectID!) {
    wsOrder(where: { id: $id }) {
      id
      locale
      email
      order_status
      payment_status
      shipping_status
      items {
        product {
          id
          wsid
          name
          price
        }
        quantity
        tax
        discount
        product_price
        variation_price
        unit_price
        total_net
        total_gross
        variations {
          name
          value
          price
        }
      }
      contact_details {
        firstname
        lastname
        phone
      }
      billing_details {
        firstname
        lastname
        company
        country
        region
        locality
        postal_code
        street_address
      }
      shipping_details {
        firstname
        lastname
        country
        region
        locality
        postal_code
        street_address
      }
      notes
      total_net
      total_gross
    }

    wsProducts {
      id
      name
      price
    }
  }
`

export const WS_ORDERS_QUERY = gql`
  query WsOrders(
    $locale: Locale
    $email: String
    $order_status: WsOrderOrderStatus
    $payment_status: WsOrderPaymentStatus
    $shipping_status: WsOrderShippingStatus
    $orderBy: WsOrderOrderByInput
    $skip: Int
    $limit: Int
  ) {
    wsOrders(
      where: {
        locale: $locale
        email: $email
        order_status: $order_status
        payment_status: $payment_status
        shipping_status: $shipping_status
      }
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      locale
      email
      order_status
      payment_status
      shipping_status
      contact_details {
        firstname
        lastname
      }
      total_net
      total_gross
      created_at
    }

    wsOrderCount(
      where: {
        locale: $locale
        email: $email
        order_status: $order_status
        payment_status: $payment_status
        shipping_status: $shipping_status
      }
    )
  }
`
