/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useRef, useContext } from 'react'
import { isEqual } from 'lodash'
import { IInputProps } from '../../form.provider'
import { Grid, Text, FlexItem } from '../../../layout'
import { InputStyles, Label } from './input.styles'
import DarkModeContext from '../../../contexts/darkmode.context'

const InputField: React.FC<IInputProps> = props => {
  const inputRef = useRef(null)
  const [snapshot, setSnapshot] = useState()
  const [newValue, setNewValue] = useState()
  const { setValue, value, fieldInfo, moduleInput } = props
  const { name, config } = fieldInfo
  const { flex, alternative } = config
  const { required, label, placeholder, validationError, validation, disabled = false } = config

  const DarkModeCtx = useContext(DarkModeContext)
  const { dark } = DarkModeCtx

  useEffect(() => {
    if (!isEqual(snapshot, value)) setSnapshot(value)
    if (!isEqual(newValue, value)) setNewValue(value)
  }, [value])

  useEffect(() => {
    if ((newValue || newValue === '' || newValue === 0) && !isEqual(newValue, value)) {
      setValue({ [name as string]: newValue })
    }
  }, [newValue])

  const onChange = (e: any) => {
    let result = e.target.value
    if (validation && validation === 'NUMBER') result = parseInt(e.target.value, 10)
    setSnapshot(result)
  }

  const onBlurChange = () => {
    setNewValue(snapshot)
  }

  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <Grid alignItems="start" alignContent="start">
        {!moduleInput && label && (
          <Grid alignItems="center" justifyItems="start" mdCol="auto 1fr">
            <Label dark={dark} alternative={alternative}>
              {label}
            </Label>
            {validationError && <Text error>{validationError}</Text>}
          </Grid>
        )}
        <InputStyles
          alternative={alternative}
          moduleInput={moduleInput}
          type={validation || 'text'}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlurChange}
          onChange={onChange}
          required={required}
          value={snapshot || ''} // TEST
          ref={inputRef}
          name={name}
          dark={dark}
        />
      </Grid>
    </FlexItem>
  )
}

export default InputField
