/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Backdrop, PopupWrapper, CloseButton, ConfirmationInput } from './popup.styles'
import { DELETE_POPUP } from '../../popup.map'
import { Button, Text, Grid, Heading } from '../../../../layout'
import Icon from '../../../../layout/icons/icons.styles'

const DeletePopup: React.FC<any> = props => {
  const { id, type, submit, hide, options } = props
  const { message, comparedValue } = options
  const [inputValue, setInputValue] = useState('')
  if (type !== DELETE_POPUP) {
    return <div>Invalid popup type!</div>
  }
  // @ TODO validations!

  const onConfirm = () => {
    submit()
    hide(id)
  }

  const onClose = () => {
    hide(id)
  }

  return (
    <Backdrop>
      <PopupWrapper>
        <CloseButton onClick={onClose} type="button">
          <Icon close />
        </CloseButton>
        <Grid gap="50px" alignItems="center">
          <Heading page>{message}</Heading>
          <Text medium>{`To delete the requested item please type [ ${comparedValue} ] below.`}</Text>
          <ConfirmationInput
            type="text"
            onChange={(e: any) => {
              setInputValue(e.target.value)
            }}
            defaultValue=""
            valid={inputValue === comparedValue}
            inValid={inputValue !== comparedValue}
          />
          <Grid alignItems="center" justifyContent="start" mdCol="auto auto" gap="30px">
            <Button secondary ghost onClick={() => hide(id)}>
              Cancel
            </Button>
            <Button onClick={onConfirm} disabled={inputValue !== comparedValue}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </PopupWrapper>
    </Backdrop>
  )
}

export default DeletePopup
