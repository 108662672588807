export default (value: string, change = 15) => {
  if (value.includes('hsla')) {
    const sep = value.indexOf(',') > -1 ? ',' : ' '
    const splitted = value
      .substr(5)
      .split(')')[0]
      .split(sep)
    const result = `hsla(${splitted[0]}, ${splitted[1]}, ${parseFloat(splitted[2]) + change}%, ${splitted[3]})`

    return result
  }
  return value
}
