/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const WS_PRODUCT_TRANSLATION_QUERY = gql`
  query WsProductTranslation($wsid: String!, $locale: Locale) {
    wsProductTranslation(where: { wsid: $wsid, locale: $locale }) {
      id
      wsid
      locale
      name
      slug
      description
      seo {
        title
        image
        keywords
        description
      }
      details {
        name
        modules {
          id
          type
          info
          data
        }
      }
      keys {
        key
        value
      }
    }

    wsProducts {
      id
      name
    }

    wsCategories {
      id
      name
    }
  }
`

export const WS_PRODUCT_TRANSLATIONS_QUERY = gql`
  query WsProductTranslations(
    $locale: Locale
    $search: String
    $orderBy: WsProductTranslationOrderByInput
    $skip: Int
    $limit: Int
    $wsid: String
  ) {
    wsProductTranslations(
      where: { locale: $locale, wsid: $wsid }
      searchInNameAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      wsid
      locale
      name
      slug
    }
    # wsProductTranslationCount(where: { locale: $locale, wsid: $wsid }, searchInNameAndSlug: $search)
  }
`
