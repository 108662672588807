/* eslint-disable no-useless-escape */
import styled from 'styled-components/macro'
import rem from '../../helpers/rem.helper'
import { IIcons } from './icons.interface'

const Icon = styled('span')<IIcons>`
  @font-face {
    font-family: 'Aery-labs-dashboard';
    src: url('/fonts/Aery-labs-dashboard.eot?bh18y9');
    src: url('/fonts/Aery-labs-dashboard.eot?bh18y9#iefix') format('embedded-opentype'),
      url('/fonts/Aery-labs-dashboard.ttf?bh18y9') format('truetype'),
      url('/fonts/Aery-labs-dashboard.woff?bh18y9') format('woff'),
      url('/fonts/Aery-labs-dashboard.svg?bh18y9#Aery-labs-dashboard') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  position: relative;
  &:after {
    font-family: 'Aery-labs-dashboard';
    position: relative;
  }
  font-size: 20px;
  ${({ size }) =>
    size &&
    `
  &:after {
    font-size: ${size};
  }
`};
  ${({ sidebar }) =>
    sidebar &&
    `
  &:after {
    font-size: 15px;
    margin-left: 15px;
    color: #e2e2e2;
    top: 1px;
  }
`};

  ${({ add }) =>
    add &&
    `
    &:after {
        content: "\\E900";
        
    }
  `};

  ${({ addfull }) =>
    addfull &&
    `
    &:after {
        content: "\\E901";
        
    }
  `};

  ${({ darkmode }) =>
    darkmode &&
    `
    &:after {
        content: "\\E902";
            }
  `};

  ${({ megaphone }) =>
    megaphone &&
    `
    &:after {
        content: "\\E903";
            }
  `};

  ${({ page }) =>
    page &&
    `
    &:after {
        content: "\\E904";
            }
  `};

  ${({ newpage }) =>
    newpage &&
    `
    &:after {
        content: "\\E905";
            }
  `};

  ${({ help }) =>
    help &&
    `
    &:after {
        content: "\\E906";
            }
  `};

  ${({ camera }) =>
    camera &&
    `
    &:after {
        content: "\\E907";
            }
  `};

  ${({ chart }) =>
    chart &&
    `
    &:after {
        content: "\\E908";
            }
  `};

  ${({ chartbar }) =>
    chartbar &&
    `
    &:after {
        content: "\\E909";
            }
  `};

  ${({ chartpie }) =>
    chartpie &&
    `
    &:after {
        content: "\\E90a";
            }
  `};

  ${({ checkmarkfull }) =>
    checkmarkfull &&
    `
    &:after {
        content: "\\E90b";
            }
  `};

  ${({ checkmark }) =>
    checkmark &&
    `
    &:after {
        content: "\\E90c";
            }
  `};

  ${({ angledown }) =>
    angledown &&
    `
    &:after {
        content: "\\E90d";
            }
  `};

  ${({ angleleft }) =>
    angleleft &&
    `
    &:after {
        content: "\\E90e";
            }
  `};

  ${({ angleright }) =>
    angleright &&
    `
    &:after {
        content: "\\E90f";
            }
  `};

  ${({ angleup }) =>
    angleup &&
    `
    &:after {
        content: "\\E910";
            }
  `};

  ${({ clipboard }) =>
    clipboard &&
    `
    &:after {
        content: "\\E911";
            }
  `};

  ${({ close }) =>
    close &&
    `
    &:after {
        content: "\\E912";
            }
  `};

  ${({ closeoutline }) =>
    closeoutline &&
    `
    &:after {
        content: "\\E913";
            }
  `};

  ${({ closefull }) =>
    closefull &&
    `
    &:after {
        content: "\\E914";
            }
  `};

  ${({ code }) =>
    code &&
    `
    &:after {
        content: "\\E915";
            }
  `};

  ${({ cog }) =>
    cog &&
    `
    &:after {
        content: "\\E916";
            }
  `};

  ${({ compose }) =>
    compose &&
    `
    &:after {
        content: "\\E917";
            }
  `};

  ${({ desktop }) =>
    desktop &&
    `
    &:after {
        content: "\\E918";
            }
  `};

  ${({ money }) =>
    money &&
    `
    &:after {
        content: "\\E919";
            }
  `};

  ${({ speed }) =>
    speed &&
    `
    &:after {
        content: "\\E91a";
            }
  `};

  ${({ options }) =>
    options &&
    `
    &:after {
        content: "\\E91b";
            }
  `};

  ${({ download }) =>
    download &&
    `
    &:after {
        content: "\\E91c";
            }
  `};

  ${({ duplicate }) =>
    duplicate &&
    `
    &:after {
        content: "\\E91d";
            }
  `};

  ${({ copy }) =>
    copy &&
    `
    &:after {
        content: "\\E91e";
            }
  `};

  ${({ edit }) =>
    edit &&
    `
    &:after {
        content: "\\E91f";
            }
  `};

  ${({ mail }) =>
    mail &&
    `
    &:after {
        content: "\\E920";
            }
  `};

  ${({ exclamationoutline }) =>
    exclamationoutline &&
    `
    &:after {
        content: "\\E921";
            }
  `};

  ${({ exclamationfull }) =>
    exclamationfull &&
    `
    &:after {
        content: "\\E922";
            }
  `};

  ${({ explore }) =>
    explore &&
    `
    &:after {
        content: "\\E923";
            }
  `};

  ${({ filter }) =>
    filter &&
    `
    &:after {
        content: "\\E924";
            }
  `};

  ${({ flag }) =>
    flag &&
    `
    &:after {
        content: "\\E925";
            }
  `};

  ${({ inboxcheck }) =>
    inboxcheck &&
    `
    &:after {
        content: "\\E926";
            }
  `};

  ${({ closesidebar }) =>
    closesidebar &&
    `
    &:after {
        content: "\\E927";
            }
  `};

  ${({ opensidebar }) =>
    opensidebar &&
    `
    &:after {
        content: "\\E928";
            }
  `};

  ${({ informationoutline }) =>
    informationoutline &&
    `
    &:after {
        content: "\\E929";
            }
  `};

  ${({ informationfull }) =>
    informationfull &&
    `
    &:after {
        content: "\\E92a";
            }
  `};

  ${({ list }) =>
    list &&
    `
    &:after {
        content: "\\E92b";
            }
  `};

  ${({ listadd }) =>
    listadd &&
    `
    &:after {
        content: "\\E92c";
            }
  `};

  ${({ routes }) =>
    routes &&
    `
    &:after {
        content: "\\E92d";
            }
  `};

  ${({ shopping }) =>
    shopping &&
    `
    &:after {
        content: "\\E92e";
            }
  `};

  ${({ lockclosed }) =>
    lockclosed &&
    `
    &:after {
        content: "\\E92f";
            }
  `};

  ${({ lockopen }) =>
    lockopen &&
    `
    &:after {
        content: "\\E930";
            }
  `};

  ${({ web }) =>
    web &&
    `
    &:after {
        content: "\\E931";
            }
  `};

  ${({ notification }) =>
    notification &&
    `
    &:after {
        content: "\\E932";
            }
  `};

  ${({ notificationoutline }) =>
    notificationoutline &&
    `
    &:after {
        content: "\\E933";
            }
  `};

  ${({ photo }) =>
    photo &&
    `
    &:after {
        content: "\\E934";
            }
  `};

  ${({ refresh }) =>
    refresh &&
    `
    &:after {
        content: "\\E935";
            }
  `};

  ${({ search }) =>
    search &&
    `
    &:after {
        content: "\\E936";
            }
  `};

  ${({ send }) =>
    send &&
    `
    &:after {
        content: "\\E937";
            }
  `};

  ${({ servers }) =>
    servers &&
    `
    &:after {
        content: "\\E938";
            }
  `};

  ${({ shoppingcart }) =>
    shoppingcart &&
    `
    &:after {
        content: "\\E939";
            }
  `};

  ${({ store }) =>
    store &&
    `
    &:after {
        content: "\\E93a";
            }
  `};

  ${({ tag }) =>
    tag &&
    `
    &:after {
        content: "\\E93b";
            }
  `};

  ${({ textbox }) =>
    textbox &&
    `
    &:after {
        content: "\\E93c";
            }
  `};

  ${({ translate }) =>
    translate &&
    `
    &:after {
        content: "\\E93d";
            }
  `};

  ${({ trash }) =>
    trash &&
    `
    &:after {
        content: "\\E93e";
            }
  `};

  ${({ settings }) =>
    settings &&
    `
    &:after {
        content: "\\E93f";
            }
  `};

  ${({ user }) =>
    user &&
    `
    &:after {
        content: "\\E940";
            }
  `};

  ${({ viewhide }) =>
    viewhide &&
    `
    &:after {
        content: "\\E941";
            }
  `};

  ${({ viewshow }) =>
    viewshow &&
    `
    &:after {
        content: "\\E942";
            }
  `};
`

export default Icon
