interface IDataFormatterConfig {
  populatedFields?: string[]
  jsonFields?: string[]
  populatedFieldsWithWsid?: string[]
  arrayOfPopulatedFieldsWithWsid?: string[]
}

export const dataFormatter: (data: any, config: IDataFormatterConfig) => any = (data, config) => {
  const formattedData: any = {}
  const { populatedFields, jsonFields, populatedFieldsWithWsid, arrayOfPopulatedFieldsWithWsid } = config

  Object.keys(data).map(key => {
    if (key === '__typename') return null
    const actualData = data[key]

    if (!actualData) {
      formattedData[key] = actualData
      return null
    }

    if (jsonFields && jsonFields.includes(key)) {
      formattedData[key] = actualData
      return null
    }

    if (populatedFields && populatedFields.includes(key)) {
      formattedData[key] = actualData.id
      return null
    }

    if (populatedFieldsWithWsid && populatedFieldsWithWsid.includes(key)) {
      formattedData[key] = actualData.wsid
      return null
    }

    if (arrayOfPopulatedFieldsWithWsid && arrayOfPopulatedFieldsWithWsid.includes(key)) {
      formattedData[key] = { create: actualData.filter(Boolean).map((item: any) => item.wsid) }
      return null
    }

    if (typeof actualData !== 'object') {
      formattedData[key] = actualData
      return null
    }

    if (Array.isArray(actualData)) {
      formattedData[key] = { create: actualData.map(item => dataFormatter(item, config)) }
      return null
    }

    formattedData[key] = { create: dataFormatter(actualData, config) }
    return null
  })

  return formattedData
}
