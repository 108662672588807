/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro'

interface IContainer {
  backgroundImage?: string
  backgroundColor?: string
  marginBottom?: string
  marginTop?: string
  maxWidth?: number
}

export const Container = styled('div')<IContainer>`
  box-sizing: border-box;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 75rem;
  z-index: 1;

  background-image: ${({ backgroundImage = '' }) => backgroundImage && `url('${backgroundImage}')`};
  background-color: ${({ backgroundColor = '' }) => backgroundColor && `${backgroundColor}`};
  margin-bottom: ${({ marginBottom = '' }) => marginBottom && `${marginBottom}`};
  margin-top: ${({ marginTop = '' }) => marginTop && `${marginTop}`};
`
