import styled from 'styled-components/macro'
import rem from '../../../helpers/rem.helper'

export const ArrayFieldButtonWrapper = styled('div')<{ isModuleField?: boolean }>`
  position: relative;
  ${({ isModuleField }) =>
    isModuleField &&
    `
  right: ${rem(260)};
  top: ${rem(33)};
  position: absolute;
  `}
`
