import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from 'react-apollo'
import { IMenuMutation } from '../../../interfaces/menu/menu.interface'
import { MENU_QUERY } from '../../../graphql/menu/queries/menu'
import { dataFormatter } from '../../../helpers/data-formatter'
import { NotificationContext } from '../../../contexts/notification.context'
import { UPDATE_MENU_MUTATION, DELETE_MENU_MUTATION } from '../../../graphql/menu/mutations/menu'
import { LOCALES } from '../../../config/variables.config'
import NotFound from '../../../components/not-found'
import FormProvider from '../../../form2/form.provider'
import { MENU_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { LOCALE_OPTIONS, STATUS_OPTIONS, LINK_TYPE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'

const MenuDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { slug: qSlug, locale: qLocale } = useParams() as { slug: string; locale: string }
  const localeUC = qLocale ? qLocale.toUpperCase() : ''
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IMenuMutation>()

  /**
   * QUERY
   */
  const { error: queryError, data: queryData } = useQuery(MENU_QUERY, { variables: { slug: qSlug, locale: localeUC } })
  useEffect(() => {
    if (queryData && queryData.menu) {
      const { menu } = queryData
      const fd = dataFormatter(menu, {})
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IMenuMutation)
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateMenu, { error: updateError, data: updateData }] = useMutation(UPDATE_MENU_MUTATION)
  const [deleteMenu, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(DELETE_MENU_MUTATION)
  useEffect(() => {
    if (!updateError && updateData && updateData.updateMenu) {
      const { updateMenu: updateMenuData } = updateData
      const fd = dataFormatter(updateMenuData, {})
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Menu Updated!' })
    }
  }, [updateData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting menu!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Menu Deleted!' })
      setTimeout(() => {
        window.location.href = `/menus`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateMenu({ variables: { id: itemID, data: newData } })
  }

  const onDelete = () => {
    deleteMenu({ variables: { id: itemID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (!LOCALES.includes(localeUC)) return <NotFound />
  if (queryError) return <div>error</div>
  if (!snapShot) return null

  const { menu } = queryData
  const { name, locale } = menu
  const { slug } = snapShot

  return (
    <div>
      <PageHeader title={`Menu Details - ${name} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onDelete={onDelete}
            deleteVerifier={slug}
            onSubmit={onUpdate}
            schema={MENU_UPDATE_SCHEMA}
            variables={{ LOCALE_OPTIONS, STATUS_OPTIONS, LINK_TYPE_OPTIONS }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default MenuDetailsPage
