/* eslint-disable @typescript-eslint/no-explicit-any */

import renderMap, { ISchemaList } from './schema.map'
import { ISchemaProvider, ISchema } from '../interfaces/schema/schema.interface'

const schemaProvider = ({ schemaName, providedVars }: ISchemaProvider): { schema?: ISchema; error?: string } => {
  const map: ISchemaList = renderMap as any
  const schema = map[schemaName] as any
  if (!schema) return { schema: undefined, error: 'Schema name is invalid!' }
  const { variables: requiredVars } = schema as ISchema

  if (requiredVars && requiredVars.length) {
    if (!providedVars) {
      return {
        schema: undefined,
        error: `required variables: ${requiredVars.map(v => ` ${v}`)}`,
      }
    }

    let missingVariable = ''
    requiredVars.map(requiredVar => {
      if (!providedVars[requiredVar]) {
        missingVariable = `${missingVariable} ${requiredVar}`
      }
      return ''
    })
    if (missingVariable) return { schema: undefined, error: `required variables: ${missingVariable}` }
  }
  return { schema, error: undefined }
}

export default schemaProvider
