/* eslint-disable react/prop-types */
import React from 'react'
import { Backdrop, PopupWrapper, CloseButton } from './popup.styles'
import { CONFIRM_POPUP } from '../../popup.map'
import { Button, Grid, Heading } from '../../../../layout'
import Icon from '../../../../layout/icons/icons.styles'

const ConfirmPopup: React.FC<any> = props => {
  const { id, type, submit, hide, options } = props
  const { message } = options
  if (type !== CONFIRM_POPUP) {
    return <div>Invalid popup type!</div>
  }

  const onConfirm = () => {
    submit()
    hide(id)
  }

  const onClose = () => {
    hide(id)
  }

  return (
    <Backdrop>
      <PopupWrapper>
        <CloseButton onClick={onClose} type="button">
          <Icon close />
        </CloseButton>
        <Grid gap="50px" alignItems="center">
          <Heading page>{message}</Heading>
          <Grid alignItems="center" justifyContent="start" mdCol="auto auto" gap="30px">
            <Button secondary ghost onClick={() => hide(id)}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </Grid>
        </Grid>
      </PopupWrapper>
    </Backdrop>
  )
}

export default ConfirmPopup
