/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const WS_CATEGORY_TRANSLATION_QUERY = gql`
  query WsCategoryTranslation($wsid: String!, $locale: Locale) {
    wsCategoryTranslation(where: { wsid: $wsid, locale: $locale }) {
      id
      wsid
      locale
      name
      slug
      description
      seo {
        title
        description
        keywords
        image
      }
    }
  }
`

export const WS_CATEGORY_TRANSLATIONS_QUERY = gql`
  query WsCategoryTranslations(
    $locale: Locale
    $search: String
    $orderBy: WsCategoryTranslationOrderByInput
    $skip: Int
    $limit: Int
    $wsid: String
  ) {
    wsCategoryTranslations(
      where: { locale: $locale, wsid: $wsid }
      searchInNameAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      wsid
      locale
      name
      slug
      updated_at
    }
    wsCategoryTranslationCount(where: { locale: $locale, wsid: $wsid }, searchInNameAndSlug: $search)
  }
`
