import styled from 'styled-components/macro'

export const FlexItem = styled('div')<{ flex?: string; alignItems?: string; margin?: string }>`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  ${({ margin }) =>
    margin &&
    `
    margin: ${margin};
  `}
  ${({ alignItems }) =>
    alignItems &&
    `
    align-items: ${alignItems};
  `}
  flex: ${({ flex }) =>
    flex &&
    `
   ${flex};
  `};
`
