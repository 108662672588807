/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro'
import { media } from './mediaqueries'

interface IGridItem {
  alignContent?: string
  area?: string
  className?: string
  justifyContent?: string
  lgHeight?: string
  lgLeft?: string
  lgTop?: string
  lgWidth?: string
  maxHeight?: string
  mdHeight?: string
  mdLeft?: string
  mdTop?: string
  mdWidth?: string
  middle?: boolean
  textAlign?: string
  xxsHeight?: string
  xxsLeft?: string
  xxsTop?: string
  xxsWidth?: string
}

export const GridItem = styled('div')<IGridItem>`
  box-sizing: border-box;
  max-width: 100%;
  display: grid;
  min-width: 0;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ area }) => area && `grid-area: ${area}`};
  ${({ middle }) =>
    middle &&
    `
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  justify-self: stretch;
  `};

  /* Defining mediaquery based behaviour of items */

  ${media.xxs} {
    ${({ xxsTop, xxsHeight }) => xxsTop && xxsHeight && `grid-row: ${xxsTop} / span ${xxsHeight}`};
    ${({ xxsLeft, xxsWidth }) => xxsLeft && xxsWidth && `grid-column: ${xxsLeft} / span ${xxsWidth}`};
  }
  ${media.md} {
    ${({ mdTop, mdHeight }) => mdTop && mdHeight && `grid-row: ${mdTop} / span ${mdHeight}`};
    ${({ mdLeft, mdWidth }) => mdLeft && mdWidth && `grid-column: ${mdLeft} / span ${mdWidth}`};
  }
  ${media.lg} {
    ${({ lgTop, lgHeight }) => lgTop && lgHeight && `grid-row: ${lgTop} / span ${lgHeight}`};
    ${({ lgLeft, lgWidth }) => lgLeft && lgWidth && `grid-column: ${lgLeft} / span ${lgWidth}`};
  }
`
