/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Grid } from '../grid'
import { Item, SwitchGrid } from './switch.styles'

interface IItem {
  title?: string
}

interface ITabSwitch {
  items: string[]
  active: string
}

const TabSwitch = ({ items, active }: ITabSwitch) => {
  return (
    <SwitchGrid
      xsCol={`repeat(${items.length}, auto)`}
      justifyItems="start"
      justifyContent="start"
      justifySelf="start"
      marginBottom="10px"
      gap="0px"
    >
      {!!items.length &&
        items.map((item, i) => (
          <Item to={`/general/${item}`} key={i} active={item === active}>
            {item}
          </Item>
        ))}
    </SwitchGrid>
  )
}

export default TabSwitch
