/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { useMutation, useLazyQuery } from 'react-apollo'
import { useParams } from 'react-router'
import { LOCALES } from '../../../config/variables.config'
import NotFound from '../../../components/not-found'
import { GENERAL_QUERY } from '../../../graphql/general/queries/general'
import { dataFormatter } from '../../../helpers/data-formatter'
import { IGeneralMutation } from '../../../interfaces/general/general.interface'
import { UPDATE_GENERAL_MUTATION, CREATE_GENERAL_MUTATION } from '../../../graphql/general/mutations/general'
import FormProvider from '../../../form2/form.provider'
import { GENERAL_UPDATE_SCHEMA, GENERAL_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { IOption } from '../../../interfaces/schema/schema.interface'
import {
  LOCALE_OPTIONS,
  GENERAL_NAVIGATION_TYPE_OPTIONS,
  GENERAL_INFO_TYPE_OPTIONS,
  GENERAL_SOCIAL_TYPE_OPTIONS,
} from '../../../config/select-options.config'
import TabSwitch from '../../../layout/switch'
import { Text, Button, PageHeader, FlexItem } from '../../../layout'
import PopupContext from '../../../contexts/popup.context'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import NotificationContext from '../../../contexts/notification.context'

interface IMenuOptions {
  id: string
  name: string
  locale: string
}

const GeneralDetails: React.FC = () => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const { locale } = useParams() as { locale: string }
  const localeUC = locale ? locale.toUpperCase() : ''
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IGeneralMutation>()
  const [menuOptions, setMenuOptions] = useState<IOption[]>()

  /**
   * QUERY
   */
  const [getGeneral, { error: queryError, data: queryData }] = useLazyQuery(GENERAL_QUERY, {
    variables: { locale: localeUC },
  })

  useEffect(() => {
    getGeneral()
  }, [locale])

  useEffect(() => {
    if (queryData && queryData.general) {
      const { general, menus = [] } = queryData
      const fd = dataFormatter(general, { populatedFields: ['menu'] })
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IGeneralMutation)
      if (menus.length)
        setMenuOptions(
          menus.map(({ id: mId, name, locale: llocale }: IMenuOptions) => ({
            label: `${name} [${llocale}]`,
            value: mId,
          })),
        )
    } else {
      setSnapshot(undefined as undefined)
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateGeneral, { error: mutationError, data: mutationData }] = useMutation(UPDATE_GENERAL_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateGeneral) {
      const { updateGeneral: updateGeneralData } = mutationData
      const fd = dataFormatter(updateGeneralData, { populatedFields: ['menu'] })
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'General successfully updated!' })
    }
  }, [mutationData, mutationError])

  // MUTATION
  const [createGeneral, { error: createGeneralMutationError, data: createGeneralMutationData }] = useMutation(
    CREATE_GENERAL_MUTATION,
  )
  useEffect(() => {
    if (!createGeneralMutationError && createGeneralMutationData && createGeneralMutationData.createGeneral) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'General successfully created!' })
      getGeneral()
    }
  }, [createGeneralMutationData])

  /**
   * POPUPS
   */
  const onClickCreate = () => {
    const initialPageData = {
      locale: localeUC,
      seo: { create: {} },
    }

    popupCtx.show({
      id: 'create-general',
      type: FORM_POPUP,
      options: {
        schema: GENERAL_CREATE_SCHEMA,
        data: initialPageData,
        variables: { LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createGeneral({ variables: { data: cbdata } }),
    })
  }

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateGeneral({ variables: { id: itemID, data: newData } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (!LOCALES.includes(localeUC)) return <NotFound />
  if (queryError) return <div>error</div>
  if (snapShot === null) return null

  /**
   * RENDER
   */
  return (
    <div>
      <PageHeader title="General Settings" />
      <FlexItem margin="-132px 25px 100px 285px">
        <TabSwitch items={LOCALES} active={localeUC} />
      </FlexItem>
      <FlexItem margin="-75px 25px 100px 25px">
        {!snapShot && (
          <div>
            <Text>Please click to create general initial information for this locale: {localeUC}!</Text>
            <Button onClick={onClickCreate} type="button">
              click here to init general {localeUC} info
            </Button>
          </div>
        )}
        {!!snapShot && (
          <FlexItem flex="1">
            <FormProvider
              onSubmit={onUpdate}
              schema={GENERAL_UPDATE_SCHEMA}
              variables={{
                LOCALE_OPTIONS,
                MENU_OPTIONS: menuOptions,
                NAVIGATION_TYPE_OPTIONS: GENERAL_NAVIGATION_TYPE_OPTIONS,
                INFO_TYPE_OPTIONS: GENERAL_INFO_TYPE_OPTIONS,
                SOCIAL_TYPE_OPTIONS: GENERAL_SOCIAL_TYPE_OPTIONS,
              }}
              data={snapShot}
            />
          </FlexItem>
        )}
      </FlexItem>
    </div>
  )
}

export default GeneralDetails
