import styled from 'styled-components/macro'
import { colors, fonts } from '../../layout'
import rem from '../../helpers/rem.helper'

interface INotificationWrapper {
  isHide?: boolean
  type?: string
}

export const NotificationListWrapper = styled('div')`
  transition: all 0.3s ease-in-out;
  z-index: 10000010;
  display: grid;
  grid-gap: ${rem(10)};
  margin: ${rem(10)};
  position: fixed;
  width: 700px;
  right: 0;
  top: 0;
`

export const NotificationWrapper = styled('div')<INotificationWrapper>`
  position: relative;
  font-family: ${fonts.osc};
  color: ${colors.white};
  ${({ isHide }) => (isHide ? `min-height: 0px;` : `min-height: 100px;  `)};
  ${({ type }) =>
    type === 'error' &&
    `
   background-color: ${colors.error};
  `}
  ${({ type }) =>
    type === 'info' &&
    `
   background-color: ${colors.info};
  `}
  ${({ type }) =>
    type === 'success' &&
    `
   background-color: ${colors.success};
  `}
`
