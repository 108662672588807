import styled from 'styled-components/macro'
import { colors } from '../../../../layout'
import rem from '../../../../helpers/rem.helper'
import { MAIN_BRAND_COLOR } from '../../../../config/layout.config'

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  color: ${colors.body};
  z-index: 100000;
  position: fixed;
  overflow: scroll;
  height: 100%;
  width: 100%;
  top: 0;
`

export const PopupWrapper = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  border-radius: ${rem(5)};
  margin: ${rem(50)} auto;
  max-width: ${rem(1000)};
  padding: ${rem(42)};
  padding-top: ${rem(76)};
  position: relative;
  /* max-height: 600px; */
  /* overflow-y: scroll; */
`

export const CloseButtonWrapper = styled.div`
  padding: 10px;
  padding-top: 16px;
  text-align: center;
  position: absolute;
  border: none;
  right: 33.6%;
  bottom: 37px;
`

export const CloseButton = styled.button`
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: ${rem(20)};
  padding: ${rem(15)};
  position: absolute;
  border: none;
  right: 0;
  top: 0;
  &:hover {
    color: ${MAIN_BRAND_COLOR};
  }
`
