/* eslint-disable @typescript-eslint/camelcase */

import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery } from 'react-apollo'
import dayjs from 'dayjs'
import { removeFalsy } from '../../../helpers/object.helper'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { PageHeader, FlexItem, Grid } from '../../../layout'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { FilterDropdown, Pagination } from '../../../components'
import {
  LOCALE_OPTIONS,
  WS_ORDER_ORDER_BY_OPTIONS,
  WS_ORDER_ORDER_STATUS_OPTIONS,
  WS_ORDER_PAYMENT_STATUS_OPTIONS,
  WS_ORDER_SHIPPING_STATUS_OPTIONS,
} from '../../../config/select-options.config'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import DarkModeContext from '../../../contexts/darkmode.context'
import { WS_ORDERS_QUERY } from '../../../graphql/ws-order/queries/ws-order'
import { IWsOrder } from '../../../interfaces/ws-order/ws-order-interface'

interface IWsOrderListQuery {
  is?: string
  locale?: string
  email?: string
  order_status?: string
  payment_status?: string
  shipping_status?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'updated_at_DESC'

const WsOrderList = () => {
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IWsOrderListQuery>({ limit: '10' })

  /**
   * QUERIES
   */
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(WS_ORDERS_QUERY, {
    variables: query,
  })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const {
        orderBy = DEFAULT_ORDER_BY,
        limit = DEFAULT_LIMIT,
        skip = DEFAULT_SKIP,
      } = searchQuery as IWsOrderListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])
  /**
   * MUTATIONS
   */

  /**
   * POPUPS
   */

  /**
   * COMPONENT LOGIC
   */
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { wsOrderCount, wsOrders: qWsOrders = [] } = queryData || ({} as { wsOrderCount: number; wsOrders: IWsOrder[] })
  const wsOrders: IWsOrder[] = qWsOrders as IWsOrder[]

  return (
    <div>
      <PageHeader title="Orders" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={3} gap="20px" marginBottom="35px">
              <FilterDropdown
                name="locale"
                label="Locale"
                value={query.locale ? { value: query.locale, label: query.locale } : undefined}
                options={LOCALE_OPTIONS as [IOption]}
                onChange={value => pushQuery('locale', value)}
              />
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={WS_ORDER_ORDER_BY_OPTIONS as [IOption]}
                onChange={value => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="order_status"
                label="Order Status"
                value={query.order_status ? { value: query.order_status, label: query.order_status } : undefined}
                options={WS_ORDER_ORDER_STATUS_OPTIONS as [IOption]}
                onChange={value => pushQuery('order_status', value)}
              />
              <FilterDropdown
                name="payment_status"
                label="Payment Status"
                value={query.payment_status ? { value: query.payment_status, label: query.payment_status } : undefined}
                options={WS_ORDER_PAYMENT_STATUS_OPTIONS as [IOption]}
                onChange={value => pushQuery('payment_status', value)}
              />
              <FilterDropdown
                name="shipping_status"
                label="Shipping Status"
                value={
                  query.shipping_status ? { value: query.shipping_status, label: query.shipping_status } : undefined
                }
                options={WS_ORDER_SHIPPING_STATUS_OPTIONS as [IOption]}
                onChange={value => pushQuery('shipping_status', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Order ID</Th>
                  <Th>Email</Th>
                  <Th>Date</Th>
                  <Th>Order Status</Th>
                  <Th>Payment Status</Th>
                  <Th>Shipping Status</Th>
                  <Th>Locale</Th>
                  <Th>Total Net</Th>
                  <Th>Total Gross</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!wsOrders.length &&
                    wsOrders.map(
                      ({
                        id,
                        email,
                        locale,
                        order_status,
                        payment_status,
                        shipping_status,
                        created_at,
                        total_gross,
                        total_net,
                      }) => {
                        return (
                          <Tr key={id} statusNew={order_status === 'NEW'}>
                            <Td>
                              <NavLink to={`/ws-order/${id}`}>{id}</NavLink>
                            </Td>
                            <Td>{email}</Td>
                            <Td>{dayjs(created_at).format('YYYY.MM.DD HH:mm:ss')}</Td>
                            <Td>{order_status}</Td>
                            <Td>{payment_status}</Td>
                            <Td>{shipping_status}</Td>
                            <Td>{locale}</Td>
                            <Td>{total_net.toFixed(2)}</Td>
                            <Td>{total_gross.toFixed(2)}</Td>
                          </Tr>
                        )
                      },
                    )}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={wsOrderCount} pushQuery={pushQuery} query={query} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default WsOrderList
