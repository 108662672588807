import styled from 'styled-components/macro'
import { colors, fonts } from '../../../layout'

export const DetailsWrapper = styled('div')`
  font-family: ${fonts.osc};

  p {
    margin-bottom: 15px;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 15px;
  }
`

export const DetailHeader = styled('div')`
  font-family: ${fonts.osc};
  color: ${colors.main};

  p:last-child {
    border-bottom: 0px solid #d4d4d4;
  }
`

export const DetailValue = styled('div')`
  font-family: ${fonts.osc};
  text-align: right;

  p:last-child {
    border-bottom: 0px solid #d4d4d4;
  }
`
