import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { Route, Switch } from 'react-router'
import { USER_ME_QUERY } from './graphql/user/queries/user'
import { Login, Sidebar, Footer, ErrorHandler, PopupProvider, NotificationProvider } from './components'
import { IUser, IAppPolicy } from './interfaces/user/user.interface'
import { UserContext } from './contexts/user.context'
import { DarkModeContext } from './contexts/darkmode.context'
import { WHITELISTED_ROLES, RESOURCE } from './config/variables.config'
import GeneralDetails from './pages/general/general-details'
import { AppFrame, Content } from './layout/appframe'
import PageList from './pages/page/page-list'
import PageDetails from './pages/page/page-details'
import HomePage from './pages/home'
// import DictionaryDetails from './pages/dictionary/dictionary-details'
import DictionaryList from './pages/dictionary/dictionary-list'
import MenuListPage from './pages/menu/menu-list'
import MenuDetailsPage from './pages/menu/menu-details'
import { GlobalStyle } from './layout/global.styles'
import WsProductDetails from './pages/ws-product/ws-product-details'
import WsProductList from './pages/ws-product/ws-product-list'
import WsCategoryList from './pages/ws-category/ws-category-list'
import WsCategoryDetails from './pages/ws-category/ws-category-details'
import WsProductTranslationDetails from './pages/ws-product-translation/ws-product-translation-details'
import WsCategoryTranslationDetails from './pages/ws-category-translation/ws-category-translation-details'
import { DARK_MODE } from './config/layout.config'
import FormList from './pages/form/form-list'
import FormDetails from './pages/form/form-details'
import FormSubmissionList from './pages/form-submission/form-submission-list'
import WsOrderList from './pages/ws-order/ws-order-list'
import FormSubmissionDetails from './pages/form-submission/form-submission-details'
import WsOrderDetails from './pages/ws-order/ws-order-details'
import DictionaryDetails from './pages/dictionary/dictionary-details'
import WsOrderDetailsOverView from './pages/ws-order/ws-order-details-overview'
import { Wrapper, Heading, Text, Container, Grid } from './layout'

const App = () => {
  const { error, data } = useQuery(USER_ME_QUERY)
  const [isOpen, setIsOpen] = useState(true)
  if (error) return <Login />
  if (!data) return null

  const { userMe } = data as { userMe: IUser }
  if (!userMe || !userMe.email) return <Login />

  const { app_policy: appPolicy } = userMe

  const policy = appPolicy.find(({ resource }) => resource === RESOURCE)
  const { role } = policy as IAppPolicy

  if (!WHITELISTED_ROLES.includes(role)) {
    return (
      <Wrapper>
        <Container maxWidth={400}>
          <Grid xxsCol="auto" alignItems="center" alignContent="center" height="100vh">
            <Heading>Thanks for signing up!</Heading>
            <Text>
              Currently you don&apos;t have permission yet. Please contact the development team who can grant you
              access.
            </Text>
          </Grid>
        </Container>
      </Wrapper>
    )
  }

  return (
    <UserContext.Provider value={userMe}>
      <DarkModeContext.Provider value={{ dark: DARK_MODE }}>
        <AppFrame dark={DARK_MODE}>
          <NotificationProvider>
            <PopupProvider>
              <ErrorHandler />
              <GlobalStyle />
              <Sidebar isOpen={isOpen} setSideBar={(val: boolean) => setIsOpen(val)} user={userMe} />
              <Content isOpen={isOpen} dark={DARK_MODE}>
                <Switch>
                  <Route path="/general/:locale" component={GeneralDetails} />
                  <Route path="/dictionary" component={DictionaryList} />
                  <Route path="/translation/:id/:locale" component={DictionaryDetails} />
                  <Route path="/pages" component={PageList} />
                  <Route path="/page/:slug/:locale" component={PageDetails} />
                  <Route path="/menus" component={MenuListPage} />
                  <Route path="/menu/:slug/:locale" component={MenuDetailsPage} />
                  <Route path="/forms" component={FormList} />
                  <Route path="/form/:slug/:locale" component={FormDetails} />
                  <Route path="/form-submissions" component={FormSubmissionList} />
                  <Route path="/form-submission/:id" component={FormSubmissionDetails} />
                  <Route path="/ws-orders" component={WsOrderList} />
                  <Route path="/ws-order/edit/:id" component={WsOrderDetails} />
                  <Route path="/ws-order/:id" component={WsOrderDetailsOverView} />
                  <Route path="/ws-products" component={WsProductList} />
                  <Route path="/ws-product/:slug" component={WsProductDetails} />
                  <Route path="/ws-product-translation/:wsid/:locale" component={WsProductTranslationDetails} />
                  <Route path="/ws-categories" component={WsCategoryList} />
                  <Route path="/ws-category/:slug" component={WsCategoryDetails} />
                  <Route path="/ws-category-translation/:wsid/:locale" component={WsCategoryTranslationDetails} />
                  <Route path="/" component={HomePage} />
                </Switch>
              </Content>
              <Footer />
            </PopupProvider>
          </NotificationProvider>
        </AppFrame>
      </DarkModeContext.Provider>
    </UserContext.Provider>
  )
}

export default App
