/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { TRANSLATION_QUERY } from '../../../graphql/translation/queries/translation'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { TRANSLATION_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { ITranslation } from '../../../interfaces/translation/translation.interface'
import {
  UPDATE_TRANSLATION_MUTATION,
  DELETE_TRANSLATION_MUTATION,
} from '../../../graphql/translation/mutations/translation'
import { LOCALE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { NotificationContext } from '../../../contexts/notification.context'
import { IOption } from '../../../interfaces/schema/schema.interface'

const DictionaryDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, id: qId } = useParams() as { locale: string; id: string }
  const capsLocale = qLocale.toUpperCase()
  const [translationID, setTranslationID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<ITranslation>()
  const [formOptions, setFormOptions] = useState<IOption[]>()

  // QUERY
  const { error: queryError, data: queryData } = useQuery(TRANSLATION_QUERY, {
    variables: { locale: capsLocale, id: qId },
  })
  useEffect(() => {
    if (queryData && queryData.translation) {
      const { translation } = queryData
      const fd = dataFormatter(translation, {})
      const { id, ...formattedData } = fd
      setTranslationID(id)
      setSnapshot(formattedData as ITranslation)

      if (queryData.forms && queryData.forms.length) {
        setFormOptions(
          queryData.forms.map(({ id: cId, alias: cAlias }: { id: string; alias: string }) => ({
            label: cAlias,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  // MUTATIONS
  const [updateTranslation, { error: mutationError, data: mutationData }] = useMutation(UPDATE_TRANSLATION_MUTATION)
  const [deleteTranslation, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_TRANSLATION_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateTranslation) {
      const { updateTranslation: updateTranslationData } = mutationData
      const fd = dataFormatter(updateTranslationData, { jsonFields: ['info', 'data'] })
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Translation Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting translation!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Translation Deleted!' })
      setTimeout(() => {
        window.location.href = `/dictionary`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // ONEVENT FUNCTIONS
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateTranslation({ variables: { id: translationID, data: newData } })
  }

  const onDelete = () => {
    deleteTranslation({ variables: { id: translationID } })
  }

  // COMPONENT LOGIC
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { translation } = queryData
  const { locale, id, key } = translation as ITranslation

  return (
    <div>
      <PageHeader title={`Page Details - ${key} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            onDelete={onDelete}
            deleteVerifier={key}
            schema={TRANSLATION_UPDATE_SCHEMA}
            variables={{ LOCALE_OPTIONS, FORM_OPTIONS: formOptions, id }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default DictionaryDetailsPage
