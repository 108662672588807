/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import schemaProvider from '../schemas/schema.provider'
import { ISchema, IField } from '../interfaces/schema/schema.interface'
import ObjectField from './fields/object/object.field'
import { ESchemaType } from '../enums/schema/schema.enum'
import { FlexItem } from '../layout'
import ActionCenter from '../components/action-center'

export interface IInputProps {
  requiredVars?: [string]
  providedVars: any
  setValue: any
  value: any
  getValues: any
  fieldInfo: IField
  actualIndex?: number
  moduleInput?: boolean
}

interface IFormProvider {
  schema: string
  variables?: any
  data: any
  onSubmit: any
  onDelete?: any
  deleteVerifier?: string
  popup?: boolean
}

const FormProvider: React.FC<IFormProvider> = props => {
  const [original, setOriginal] = useState()
  const [snapshot, setSnapshot] = useState()
  const { schema: schemaName, variables: providedVars, data, onSubmit, onDelete, popup, deleteVerifier } = props

  useEffect(() => {
    setOriginal(data)
    setSnapshot(data)
  }, [data])

  const { schema, error } = schemaProvider({ schemaName, providedVars })
  if (error) return <div style={{ color: 'red' }}>{error}</div>

  const { fields, variables: requiredVars } = schema as ISchema

  const onCancelWrapper = () => {
    setSnapshot(original)
  }
  const onSubmitWrapper = (e: any) => {
    e.preventDefault()
    onSubmit(snapshot)
  }

  const isModified = !isEqual(original, snapshot)

  const setValue = (value: any) => {
    setSnapshot({ ...snapshot, ...value.ROOT })
  }

  const getValues = () => {
    return snapshot
  }

  if (!snapshot) return null
  return (
    <FlexItem flex="100%">
      <form style={{ width: '100%' }} onSubmit={onSubmitWrapper}>
        <FlexItem alignItems="start" flex="100%">
          <ObjectField
            setValue={setValue}
            getValues={getValues}
            value={snapshot}
            fieldInfo={{ name: 'ROOT', type: '' as ESchemaType, config: {}, fields }}
            requiredVars={requiredVars}
            providedVars={providedVars}
          />
          <ActionCenter
            onCancel={onCancelWrapper}
            isModified={isModified}
            comparedValue={deleteVerifier}
            onDelete={onDelete}
            popup={popup}
            top
          />
          {!popup && (
            <ActionCenter
              onCancel={onCancelWrapper}
              isModified={isModified}
              comparedValue={deleteVerifier}
              onDelete={onDelete}
              popup={popup}
              bottom
            />
          )}
        </FlexItem>
      </form>
    </FlexItem>
  )
}

export default FormProvider
