import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from 'react-apollo'
import NotificationContext from '../../../contexts/notification.context'
import { WS_CATEGORY_TRANSLATION_QUERY } from '../../../graphql/ws-category-translation/queries/ws-category-translation'
import { dataFormatter } from '../../../helpers/data-formatter'
import { UPDATE_WS_CATEGORY_TRANSLATION_MUTATION } from '../../../graphql/ws-category-translation/mutations/ws-category-translation'
import { PageHeader, FlexItem } from '../../../layout'
import FormProvider from '../../../form2/form.provider'
import { IWsCategoryTranslation } from '../../../interfaces/ws-category-translation/ws-category-translation.interface'
import { LOCALE_OPTIONS } from '../../../config/select-options.config'
import { WS_CATEGORY_TRANSLATION_UPDATE_SCHEMA } from '../../../schemas/schema.map'

const WsCategoryTranslationDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { wsid: qwsid, locale: qLocale } = useParams() as { wsid: string; locale: string }
  const localeUC = qLocale ? qLocale.toUpperCase() : ''
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IWsCategoryTranslation>()

  /**
   * QUERIES
   */
  const { error: queryError, data: queryData } = useQuery(WS_CATEGORY_TRANSLATION_QUERY, {
    variables: { wsid: qwsid, locale: localeUC },
  })
  useEffect(() => {
    if (queryData && queryData.wsCategoryTranslation) {
      const { wsCategoryTranslation } = queryData
      const fd = dataFormatter(wsCategoryTranslation, {})
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IWsCategoryTranslation)
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateWsCategoryTranslation, { error: mutationError, data: mutationData }] = useMutation(
    UPDATE_WS_CATEGORY_TRANSLATION_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateWsCategoryTranslation) {
      const { updateWsCategoryTranslation: updateWsCategoryTranslationData } = mutationData
      const fd = dataFormatter(updateWsCategoryTranslationData, {})
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData as IWsCategoryTranslation)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Translation Updated!' })
    }
  }, [mutationData])

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateWsCategoryTranslation({ variables: { id: itemID, data: newData } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null
  const { slug } = snapShot

  return (
    <div>
      <PageHeader title="Category Translation Details" />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            schema={WS_CATEGORY_TRANSLATION_UPDATE_SCHEMA}
            variables={{ LOCALE_OPTIONS }}
            data={snapShot}
            deleteVerifier={slug}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default WsCategoryTranslationDetailsPage
