import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation, useLazyQuery } from 'react-apollo'
import { IWsProduct } from '../../../interfaces/ws-product/ws-product.interface'
import NotificationContext from '../../../contexts/notification.context'
import PopupContext from '../../../contexts/popup.context'
import { WS_PRODUCT_QUERY } from '../../../graphql/ws-product/queries/ws-product'
import { dataFormatter } from '../../../helpers/data-formatter'
import { STATUS_OPTIONS, TAX_OPTIONS, LOCALE_OPTIONS } from '../../../config/select-options.config'
import {
  UPDATE_WS_PRODUCT_MUTATION,
  DELETE_WS_PRODUCT_MUTATION,
} from '../../../graphql/ws-product/mutations/ws-product'
import { PageHeader, FlexItem, Grid, Button, ButtonLink } from '../../../layout'
import FormProvider from '../../../form2/form.provider'
import { WS_PRODUCT_UPDATE_SCHEMA, WS_PRODUCT_TRANSLATION_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { ICategoryOptions } from '../ws-product-list/ws-product-list'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { Table, Thead, Tbody, Th, Tr, Td } from '../../../layout/table/table.styles'
import { LOCALES, DEFAULT_LOCALE } from '../../../config/variables.config'
import { WS_PRODUCT_TRANSLATIONS_QUERY } from '../../../graphql/ws-product-translation/queries/ws-product-translation'
import {
  CREATE_WS_PRODUCT_TRANSLATION_MUTATION,
  UPDATE_WS_PRODUCT_TRANSLATION_MUTATION,
} from '../../../graphql/ws-product-translation/mutations/ws-product-translation'
import { SectionWrapper, SectionHeader, SectionContent } from '../../../layout/section/section.styles'
import DarkModeContext from '../../../contexts/darkmode.context'
import { IWsProductTranslation } from '../../../interfaces/ws-product-translation/ws-product-translation.interface'

const WsProductDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const popupCtx = useContext(PopupContext)
  const { slug: qSlug } = useParams() as { slug: string }
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IWsProduct>()
  const [wsCategoryOptions, setWsCategoryOptions] = useState<IOption[]>()
  const [wsProductOptions, setWsProductOptions] = useState<IOption[]>()
  const [wsCategoryOptionsWsid, setWsCategoryOptionsWsid] = useState<IOption[]>()
  const [featuredProductsOptions, setFeaturedProductsOptions] = useState<IOption[]>()

  /**
   * QUERIES
   */

  // fetch ws-product-translation details
  const [getTranslations, { data: translationsData }] = useLazyQuery(WS_PRODUCT_TRANSLATIONS_QUERY)
  // fetch ws-product details and (featured) product list and category list
  const { error: queryError, data: queryData } = useQuery(WS_PRODUCT_QUERY, { variables: { slug: qSlug } })
  useEffect(() => {
    if (queryData && queryData.wsProduct) {
      const { wsProduct } = queryData
      const fd = dataFormatter(wsProduct, {
        arrayOfPopulatedFieldsWithWsid: ['featured_products'],
        populatedFieldsWithWsid: ['category'],
        jsonFields: ['info', 'data'],
      })
      const { id, ...formattedData } = fd

      setItemID(id)
      setSnapshot(formattedData as IWsProduct)
      getTranslations({ variables: { wsid: wsProduct.wsid } })

      if (queryData.wsCategories && queryData.wsCategories.length) {
        setWsCategoryOptions(
          queryData.wsCategories.map(({ id: cId, name: cName }: ICategoryOptions) => ({
            label: cName,
            value: cId,
          })),
        )
        setWsCategoryOptionsWsid(
          queryData.wsCategories.map(({ wsid: cWsid, name: cName }: ICategoryOptions) => ({
            label: cName,
            value: cWsid,
          })),
        )
      }
      if (queryData.wsProducts && queryData.wsProducts.length) {
        setWsProductOptions(
          queryData.wsProducts.map(({ id: cId, name: cName }: ICategoryOptions) => ({
            label: cName,
            value: cId,
          })),
        )
        setFeaturedProductsOptions(
          queryData.wsProducts
            .map(({ wsid: cWsid, name: cName }: ICategoryOptions) => {
              if (cWsid === wsProduct.wsid) return null
              return {
                label: cName,
                value: cWsid,
              }
            })
            .filter(Boolean),
        )
      }
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  // update ws-category
  const [updateWsProduct, { error: mutationError, data: mutationData }] = useMutation(UPDATE_WS_PRODUCT_MUTATION)
  const [deleteWsProduct, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_WS_PRODUCT_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateWsProduct) {
      const { updateWsProduct: updateWsProductData } = mutationData
      const fd = dataFormatter(updateWsProductData, {
        arrayOfPopulatedFieldsWithWsid: ['featured_products'],
        populatedFieldsWithWsid: ['category'],
        jsonFields: ['info', 'data'],
      })
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData as IWsProduct)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Product Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting product!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Product Deleted!' })
      setTimeout(() => {
        window.location.href = `/ws-products`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // create ws-product-translation
  const [
    createWsProductTranslation,
    { error: createWsProductTranslationError, data: createWsProductTranslationData },
  ] = useMutation(CREATE_WS_PRODUCT_TRANSLATION_MUTATION)
  useEffect(() => {
    if (
      !createWsProductTranslationError &&
      createWsProductTranslationData &&
      createWsProductTranslationData.createWsProductTranslation
    ) {
      window.location.href = `ws-product-translation/${createWsProductTranslationData.createWsProductTranslation.wsid}/${createWsProductTranslationData.createWsProductTranslation.locale}`
    }
  }, [createWsProductTranslationData])

  // update ws-product-translation
  const [
    updateWsProductTranslation,
    { error: updateWsProductTranslationError, data: updateWsProductTranslationData },
  ] = useMutation(UPDATE_WS_PRODUCT_TRANSLATION_MUTATION)

  /**
   * POPUPS
   */
  // create and open translation popup
  const onClickCreateTranslation = (locale: string) => {
    const initialData = {
      wsid: snapShot ? snapShot.wsid : '',
      locale,
      seo: { create: {} },
    }

    popupCtx.show({
      id: 'create-ws-category-translation',
      type: FORM_POPUP,
      options: {
        schema: WS_PRODUCT_TRANSLATION_CREATE_SCHEMA,
        data: initialData,
        variables: { LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createWsProductTranslation({ variables: { data: cbdata } }),
    })
  }

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }

    // modify wsProductTranslations wsid
    if (snapShot && data && snapShot.wsid !== data.wsid) {
      if (translationsData && translationsData.wsProductTranslations && translationsData.wsProductTranslations.length) {
        const { wsProductTranslations } = translationsData as { wsProductTranslations: IWsProductTranslation[] }
        wsProductTranslations.forEach(translationItem => {
          const { id: tID, locale: tLocale, name: tName, slug: tSlug } = translationItem
          updateWsProductTranslation({
            variables: { id: tID, data: { locale: tLocale, name: tName, slug: tSlug, wsid: data.wsid } },
          })
        })
      }
    }

    updateWsProduct({ variables: { id: itemID, data: newData } })
  }

  const onDelete = () => {
    deleteWsProduct({ variables: { id: itemID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { wsProduct } = queryData
  const { name } = wsProduct
  const { slug } = snapShot

  return (
    <div>
      <PageHeader title={`Product Details - ${name}`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onDelete={onDelete}
            deleteVerifier={slug}
            onSubmit={onUpdate}
            schema={WS_PRODUCT_UPDATE_SCHEMA}
            variables={{
              STATUS_OPTIONS,
              TAX_OPTIONS,
              WS_CATEGORY_OPTIONS: wsCategoryOptions,
              WS_CATEGORY_OPTIONS_WSID: wsCategoryOptionsWsid,
              FEATURED_PRODUCTS_OPTIONS: featuredProductsOptions,
              WS_PRODUCT_OPTIONS: wsProductOptions,
            }}
            data={snapShot}
          />
          <Grid marginTop="30px" marginRight="20px">
            <SectionWrapper>
              <SectionHeader dark={dark}>Translations</SectionHeader>
              <SectionContent>
                <Table>
                  <Thead>
                    <Th>Locale</Th>
                    <Th textAlign="right">Actions</Th>
                  </Thead>
                  <Tbody>
                    {!!LOCALES.length &&
                      LOCALES.map(locale => {
                        if (locale === DEFAULT_LOCALE) return null
                        let trasnlations = []
                        if (translationsData && translationsData.wsProductTranslations)
                          trasnlations = translationsData.wsProductTranslations
                        const isExist = trasnlations.find(({ locale: l }: any) => l === locale)

                        return (
                          <Tr key={locale}>
                            <Td>{locale}</Td>
                            {!isExist && <Button onClick={() => onClickCreateTranslation(locale)}>ADD</Button>}
                            {isExist && (
                              <Td textAlign="right">
                                <ButtonLink ghost to={`/ws-product-translation/${snapShot.wsid}/${locale}`}>
                                  EDIT
                                </ButtonLink>
                              </Td>
                            )}
                          </Tr>
                        )
                      })}
                  </Tbody>
                </Table>
              </SectionContent>
            </SectionWrapper>
          </Grid>
        </FlexItem>
      )}
    </div>
  )
}

export default WsProductDetailsPage
