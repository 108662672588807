/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useQuery } from 'react-apollo'
import { PageHeader, FlexItem, Grid } from '../../layout'
import { UserContext } from '../../contexts/user.context'
import { SectionWrapper, SectionHeader } from '../../layout/section/section.styles'
import { IUser } from '../../interfaces/user/user.interface'
import { IWsOrder } from '../../interfaces/ws-order/ws-order-interface'
import { WS_ORDERS_QUERY } from '../../graphql/ws-order/queries/ws-order'

const HomePage = () => {
  const userMe = useContext(UserContext)
  const { firstname } = userMe || {}
  const actualMonth = dayjs().format('M')
  const [wsOrderOfThisMonth, setWsOrderOfThisMonth] = useState<IWsOrder[]>([])
  const [revenue, setRevenue] = useState<{ net: number; gross: number }>()

  const { error: queryError, data: queryData, loading } = useQuery(WS_ORDERS_QUERY, {
    variables: { orderBy: 'created_at_DESC' },
  })

  useEffect(() => {
    if (queryData && queryData.wsOrders && queryData.wsOrders.length) {
      const { wsOrders } = queryData as { wsOrders: IWsOrder[] }

      const orderFromThisMonth: IWsOrder[] = []
      const revenueOfThemonth = { net: 0, gross: 0 }

      wsOrders.forEach(order => {
        const { created_at, order_status, total_net, total_gross } = order

        // collect all orders in the recent month
        if (actualMonth === dayjs(created_at).format('M')) {
          if (order_status === 'DONE_DEAL') {
            revenueOfThemonth.net += total_net
            revenueOfThemonth.gross += total_gross
          }

          orderFromThisMonth.push(order)
        }
      })

      setRevenue(revenueOfThemonth)
      setWsOrderOfThisMonth(orderFromThisMonth)
    }
  }, [queryData])

  if (queryError) return <div>error</div>

  return (
    <div>
      <PageHeader title={`Greetings ${firstname}!`} withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark>Numbers of {dayjs().format('MMMM')}</SectionHeader>
            <Grid mdCol={3} gap="20px" marginBottom="35px">
              <div>
                <p>Orders by statuses</p>
                <br />
                <p>New: {wsOrderOfThisMonth.filter(({ order_status }) => order_status === 'NEW').length}</p>
                <p>Pending: {wsOrderOfThisMonth.filter(({ order_status }) => order_status === 'PENDING').length}</p>
                <p>Done Deal: {wsOrderOfThisMonth.filter(({ order_status }) => order_status === 'DONE_DEAL').length}</p>
                <p>
                  Done Unsuccessful:{' '}
                  {wsOrderOfThisMonth.filter(({ order_status }) => order_status === 'DONE_UNSUCCESSFUL').length}
                </p>
                <p>
                  Done Remarketing:{' '}
                  {wsOrderOfThisMonth.filter(({ order_status }) => order_status === 'DONE_REMARKETING').length}
                </p>
              </div>
              <div>
                <p>Total Revenue (all DONE_DEAL order revenue)</p>
                <br />
                <p>Net: {revenue && revenue.net} EUR</p>
                <p>Gross: {revenue && revenue.gross} EUR</p>
              </div>
            </Grid>
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default HomePage
