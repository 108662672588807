/* eslint-disable @typescript-eslint/camelcase */

import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery, useMutation } from 'react-apollo'
import PopupContext from '../../../contexts/popup.context'
import NotificationContext from '../../../contexts/notification.context'
import { removeFalsy } from '../../../helpers/object.helper'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { PageHeader, FlexItem, Grid, Button } from '../../../layout'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { FilterSearch, FilterDropdown, Pagination } from '../../../components'
import { STATUS_OPTIONS, FORMS_ORDER_BY_OPTIONS, LOCALE_OPTIONS } from '../../../config/select-options.config'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { DEFAULT_STATUS } from '../../../config/variables.config'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { FORM_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { FORMS_QUERY } from '../../../graphql/form/queries/form'
import { IForm } from '../../../interfaces/form/form-interface'
import { CREATE_FORM_MUTATION } from '../../../graphql/form/mutations/form'
import DarkModeContext from '../../../contexts/darkmode.context'

interface IFormListQuery {
  is?: string
  locale?: string
  status?: string
  search?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'title_ASC'

const FormList = () => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IFormListQuery>({ limit: '10' })

  /**
   * QUERIES
   */
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(FORMS_QUERY, { variables: query })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const { orderBy = DEFAULT_ORDER_BY, limit = DEFAULT_LIMIT, skip = DEFAULT_SKIP } = searchQuery as IFormListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])

  /**
   * MUTATIONS
   */
  const [createForm, { error: mutationError, data: mutationData }] = useMutation(CREATE_FORM_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.createForm) {
      const { createForm: createFormData } = mutationData
      window.location.href = `form/${createFormData.slug}/${createFormData.locale}`
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully created!' })
    }
  }, [mutationData])

  /**
   * POPUPS
   */
  const onClickCreate = () => {
    const initialData = {
      status: DEFAULT_STATUS,
      fields: { create: [] },
      error_info: { create: {} },
      success_info: { create: {} },
      mailer_info: {
        create: {
          auto_email_sending: false,
          auto_feedback_sending: false,
        },
      },
    }
    popupCtx.show({
      id: 'create-form',
      type: FORM_POPUP,
      options: {
        schema: FORM_CREATE_SCHEMA,
        data: initialData,
        variables: { STATUS_OPTIONS, LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createForm({ variables: { data: cbdata } }),
    })
  }

  /**
   * COMPONENT LOGIC
   */
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { formCount, forms: qForms = [] } = queryData || ({} as { wsCategoryCount: number; wsProducts: IForm[] })
  const forms: IForm[] = qForms as IForm[]

  return (
    <div>
      <PageHeader title="Forms" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Actions</SectionHeader>
            <Grid mdCol="1fr auto" gap="20px" alignContent="end" justifyContent="start" alignItems="end">
              <FilterSearch
                name="search"
                label="Search (in name and slug and brand)"
                value={query.search}
                onChange={value => pushQuery('search', value)}
              />
              <Button ghost onClick={onClickCreate}>
                Create New Form
              </Button>
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={2} gap="20px">
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={FORMS_ORDER_BY_OPTIONS as [IOption]}
                onChange={value => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={STATUS_OPTIONS as [IOption]}
                onChange={value => pushQuery('status', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Title</Th>
                  <Th>Slug</Th>
                  <Th>Status</Th>
                  <Th>Locale</Th>
                  <Th>id</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!forms.length &&
                    forms.map(({ id, title, locale, slug, status }) => {
                      return (
                        <Tr key={id}>
                          <Td>
                            <NavLink to={`/form/${slug}/${locale}`}>{title}</NavLink>
                          </Td>
                          <Td>{slug}</Td>
                          <Td>{status}</Td>
                          <Td>{locale}</Td>
                          <Td>{id}</Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={formCount} pushQuery={pushQuery} query={query} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default FormList
