/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_WS_PRODUCT_MUTATION = gql`
  mutation CreateWsProduct($data: WsProductCreateInput!) {
    createWsProduct(data: $data) {
      id
      wsid
      status
      name
      slug
      position
      brand
      description
      price
      tax
      discount
    }
  }
`

export const CLONE_WS_PRODUCT_MUTATION = gql`
  mutation CloneWsProduct($id: ObjectID!, $tag: String) {
    cloneWsProduct(where: { id: $id }, tag: $tag) {
      id
      wsid
      name
      slug
    }
  }
`

export const UPDATE_WS_PRODUCT_MUTATION = gql`
  mutation UpdateWsProduct($id: ObjectID!, $data: WsProductUpdateInput!) {
    updateWsProduct(where: { id: $id }, data: $data) {
      id
      wsid
      status
      name
      slug
      position
      brand
      description
      seo {
        title
        description
        keywords
        image
      }
      details {
        name
        modules {
          id
          type
          info
          data
        }
      }
      price
      tax
      discount
      variations {
        id
        name
        translation_key
        options {
          id
          name
          image
          translation_key
          price
        }
      }
      featured_image
      thumbnail_image
      gallery {
        alt
        url
        variation_key
      }
      category {
        id
        wsid
        name
        slug
      }
      featured_products {
        wsid
        name
        slug
      }
    }
  }
`

export const DELETE_WS_PRODUCT_MUTATION = gql`
  mutation DeleteWsProduct($id: ObjectID!) {
    deleteWsProduct(where: { id: $id }) {
      id
    }
  }
`
