import MenuUpdateSchema from './menu/menu-update.schema.json'
import MenuCreateSchema from './menu/menu-create.schema.json'
import GeneralUpdateSchema from './general/general-update.schema.json'
import GeneralCreateSchema from './general/general-create.schema.json'
import PageCreateSchema from './page/page-create.schema.json'
import PageUpdateSchema from './page/page-update.schema.json'
import TranslationCreateSchema from './translation/translation-create.schema.json'
import TranslationUpdateSchema from './translation/translation-update.schema.json'
import FormUpdateSchema from './form/form-update.schema.json'
import FormCreateSchema from './form/form-create.schema.json'
import FormFieldsSchema from './form/form-fields.schema.json'
import FormFieldsOptionsSchema from './form/form-fields-options.schema.json'
import FormSubmissionUpdateSchema from './form-submission/form-submission-update.schema.json'
// Ws
import WsProductUpdateSchema from './ws-product/ws-product-update.schema.json'
import WsProductCreateSchema from './ws-product/ws-product-create.schema.json'
import WsProductGallerySchema from './ws-product/ws-product-gallery.schema.json'
import WsProductVariationsSchema from './ws-product/ws-product-variations.schema.json'
import WsProductDetailsModulesSchema from './ws-product/ws-product-details-modules.schema.json'
import WsProductTranslationUpdateSchema from './ws-product-translation/ws-product-translation-update.schema.json'
import WsProductTranslationCreateSchema from './ws-product-translation/ws-product-translation-create.schema.json'
import WsCategoryUpdateSchema from './ws-category/ws-category-update.schema.json'
import WsCategoryCreateSchema from './ws-category/ws-category-create.schema.json'
import WsCategoryTranslationUpdateSchema from './ws-category-translation/ws-category-translation-update.schema.json'
import WsCategoryTranslationCreateSchema from './ws-category-translation/ws-category-translation-create.schema.json'
import WsOrderUpdateSchema from './ws-order/ws-order-update.schema.json'
import WsOrderItemsVariationsSchema from './ws-order/ws-order-items-variations.schema.json'
// Modules
import Info from './modules/info.schema.json'
import ButtonSchema from './modules/button.schema.json'
import ImageSchema from './modules/image.schema.json'
import HeroModule from './modules/hero-module.schema.json'
import HeadingModule from './modules/heading-module.schema.json'
import TextModule from './modules/text-module.schema.json'
import TextAndImageModule from './modules/text-and-image-module.schema.json'
import FullWidthImageModule from './modules/full-width-image-module.schema.json'
import FeaturedTilesModule from './modules/featured-tiles-module.schema.json'
import FeaturedTilesSlidesSchema from './modules/featured-tiles-slides.schema.json'
import BenefitsModule from './modules/benefits-module.schema.json'
import IconsModule from './modules/icons-module.schema.json'
import VideoModule from './modules/video-module.schema.json'
import FormModule from './modules/form-module.schema.json'
import SpecificationsModule from './modules/specifications-module.schema.json'
import FeaturedProductsModule from './modules/featured-products-module.schema.json'
import FeaturedCategoriesModule from './modules/featured-categories-module.schema.json'
import ProductHighlightModule from './modules/product-highlight-module.schema.json'

export const MENU_UPDATE_SCHEMA = 'MenuUpdateSchema'
export const MENU_CREATE_SCHEMA = 'MenuCreateSchema'
export const GENERAL_UPDATE_SCHEMA = 'GeneralUpdateSchema'
export const GENERAL_CREATE_SCHEMA = 'GeneralCreateSchema'
export const PAGE_UPDATE_SCHEMA = 'PageUpdateSchema'
export const PAGE_CREATE_SCHEMA = 'PageCreateSchema'
export const TRANSLATION_CREATE_SCHEMA = 'TranslationCreateSchema'
export const TRANSLATION_UPDATE_SCHEMA = 'TranslationUpdateSchema'
export const FORM_UPDATE_SCHEMA = 'FormUpdateSchema'
export const FORM_CREATE_SCHEMA = 'FormCreateSchema'
export const FORM_SUBMISSION_UPDATE_SCHEMA = 'FormSubmissionUpdateSchema'
// Ws
export const WS_PRODUCT_UPDATE_SCHEMA = 'WsProductUpdateSchema'
export const WS_PRODUCT_CREATE_SCHEMA = 'WsProductCreateSchema'
export const WS_PRODUCT_TRANSLATION_UPDATE_SCHEMA = 'WsProductTranslationUpdateSchema'
export const WS_PRODUCT_TRANSLATION_CREATE_SCHEMA = 'WsProductTranslationCreateSchema'
export const WS_CATEGORY_UPDATE_SCHEMA = 'WsCategoryUpdateSchema'
export const WS_CATEGORY_CREATE_SCHEMA = 'WsCategoryCreateSchema'
export const WS_CATEGORY_TRANSLATION_UPDATE_SCHEMA = 'WsCategoryTranslationUpdateSchema'
export const WS_CATEGORY_TRANSLATION_CREATE_SCHEMA = 'WsCategoryTranslationCreateSchema'
export const WS_ORDER_UPDATE_SCHEMA = 'WsOrderUpdateSchema'

export interface ISchemaList {
  MenuUpdateSchema: string
  MenuCreateSchema: string
  GeneralUpdateSchema: string
  GeneralCreateSchema: string
  PageUpdateSchema: string
  PageCreateSchema: string
  TranslationUpdateSchema: string
  TranslationCreateSchema: string
  FormUpdateSchema: string
  FormCreateSchema: string
  FormFieldsSchema: string
  FormFieldsOptionsSchema: string
  FormSubmissionUpdateSchema: string
  // Ws
  WsProductUpdateSchema: string
  WsProductCreateSchema: string
  WsProductGallerySchema: string
  WsProductVariationsSchema: string
  WsProductDetailsModulesSchema: string
  WsProductTranslationUpdateSchema: string
  WsProductTranslationCreateSchema: string
  WsCategoryUpdateSchema: string
  WsCategoryCreateSchema: string
  WsCategoryTranslationUpdateSchema: string
  WsCategoryTranslationCreateSchema: string
  WsOrderUpdateSchema: string
  WsOrderItemsVariationsSchema: string
  // Modules
  Info: string
  ButtonSchema: string
  Button2Schema: string
  ImageSchema: string
  HeroModule: string
  HeadingModule: string
  TextModule: string
  TextAndImageModule: string
  FullWidthImageModule: string
  FeaturedTilesModule: string
  FeaturedTilesSlidesSchema: string
  BenefitsModule: string
  IconsModule: string
  VideoModule: string
  SpecificationsModule: string
  FormModule: string
  FeaturedProductsModule: string
  FeaturedCategoriesModule: string
  ProductHighlightModule: string

  [key: string]: string
}

export default {
  MenuUpdateSchema,
  MenuCreateSchema,
  GeneralUpdateSchema,
  GeneralCreateSchema,
  PageUpdateSchema,
  PageCreateSchema,
  TranslationUpdateSchema,
  TranslationCreateSchema,
  FormUpdateSchema,
  FormCreateSchema,
  FormFieldsSchema,
  FormFieldsOptionsSchema,
  FormSubmissionUpdateSchema,
  // Ws
  WsProductUpdateSchema,
  WsProductCreateSchema,
  WsProductGallerySchema,
  WsProductVariationsSchema,
  WsProductDetailsModulesSchema,
  WsProductTranslationUpdateSchema,
  WsProductTranslationCreateSchema,
  WsCategoryUpdateSchema,
  WsCategoryCreateSchema,
  WsCategoryTranslationUpdateSchema,
  WsCategoryTranslationCreateSchema,
  WsOrderUpdateSchema,
  WsOrderItemsVariationsSchema,
  // Modules
  Info,
  ButtonSchema,
  Button2Schema: ButtonSchema,
  ImageSchema,
  HeroModule,
  HeadingModule,
  TextModule,
  TextAndImageModule,
  FullWidthImageModule,
  FeaturedTilesModule,
  FeaturedTilesSlidesSchema,
  BenefitsModule,
  IconsModule,
  VideoModule,
  SpecificationsModule,
  FormModule,
  FeaturedProductsModule,
  FeaturedCategoriesModule,
  ProductHighlightModule,
}
