import { gql } from 'apollo-boost'

export const UPDATE_WS_ORDER_MUTATION = gql`
  mutation UpdateWsOrder($id: ObjectID!, $data: WsOrderUpdateInput!) {
    updateWsOrder(where: { id: $id }, data: $data) {
      id
      locale
      email
      order_status
      payment_status
      shipping_status
      items {
        product {
          id
          name
          price
        }
        quantity
        tax
        discount
        product_price
        variation_price
        unit_price
        total_net
        total_gross
        variations {
          name
          value
          price
        }
      }
      contact_details {
        firstname
        lastname
        phone
      }
      billing_details {
        firstname
        lastname
        company
        country
        region
        locality
        postal_code
        street_address
      }
      shipping_details {
        firstname
        lastname
        country
        region
        locality
        postal_code
        street_address
      }
      notes
      total_net
      total_gross
    }
  }
`

export const DELETE_WS_ORDER_MUTATION = gql`
  mutation DeleteWsOrder($id: ObjectID!) {
    deleteWsOrder(where: { id: $id }) {
      id
    }
  }
`
