/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useContext } from 'react'
import ReactSelect from 'react-select'
import { isEqual } from 'lodash'
import { IInputProps } from '../../form.provider'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Grid, FlexItem } from '../../../layout'
import { Label } from '../input/input.styles'
import { selectStyle, selectAlternativeStyle, selectAlternativeDarkStyle, SelectWrapper } from './select.styles'
import DarkModeContext from '../../../contexts/darkmode.context'

const SelectField: React.FC<IInputProps> = props => {
  const [snapshot, setSnapshot] = useState<IOption>()
  const { setValue, value, fieldInfo } = props
  const { name, config } = fieldInfo
  const { flex } = config
  const { required, options = {}, label, disabled = false, alternative = false } = config
  const DarkModeCtx = useContext(DarkModeContext)
  const { dark } = DarkModeCtx

  useEffect(() => {
    const defaultOption = (options as IOption[]).find(({ value: oValue }) => oValue === value)
    setSnapshot(defaultOption)
  }, [value, name])

  useEffect(() => {
    if ((snapshot || snapshot === '') && !isEqual(snapshot, value)) {
      setValue({ [name as string]: (snapshot as IOption).value })
    }
  }, [snapshot])

  const onChange = (oValue: any) => {
    setSnapshot(oValue)
  }

  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <Grid>
        {label && (
          <Label alternative={alternative} dark={dark}>
            {label}
          </Label>
        )}
        <SelectWrapper>
          <ReactSelect
            options={options as [IOption]}
            isDisabled={disabled}
            styles={alternative ? (dark ? selectAlternativeDarkStyle : selectAlternativeStyle) : selectStyle}
            onChange={onChange}
            value={snapshot}
            name={name}
            className={`${disabled && 'disabled'}`}
          />
          {required && (
            <input
              style={{
                position: 'absolute',
                opacity: 0,
                padding: 0,
                height: 0,
                margin: 0,
              }}
              required={required}
              autoComplete="off"
              tabIndex={-1}
              value={value}
            />
          )}
        </SelectWrapper>
      </Grid>
    </FlexItem>
  )
}

export default SelectField
