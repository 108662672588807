import React from 'react'
import { FooterStyle } from './footer.styles'

const date = new Date()
const Footer = () => (
  <div style={{ position: 'relative' }}>
    <FooterStyle>© {date.getFullYear()}, Designed & Coded by Aery Labs - aerylabs.io</FooterStyle>
  </div>
)

export default Footer
