/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_MENU_MUTATION = gql`
  mutation CreateMenu($data: MenuCreateInput!) {
    createMenu(data: $data) {
      id
      locale
      status
      name
      slug
    }
  }
`

export const UPDATE_MENU_MUTATION = gql`
  mutation UpdateMenu($id: ObjectID!, $data: MenuUpdateInput!) {
    updateMenu(where: { id: $id }, data: $data) {
      id
      locale
      status
      name
      slug
      links {
        id
        type
        name
        url
      }
    }
  }
`

export const DELETE_MENU_MUTATION = gql`
  mutation DeleteMenu($id: ObjectID!) {
    deleteMenu(where: { id: $id }) {
      id
    }
  }
`
