import styled from 'styled-components'
import rem from '../../helpers/rem.helper'
import { WINDOW_BORDER_RADIUS } from '../../config/layout.config'
import { colors } from '../../layout'

export const LoginWindow = styled('div')<{ backgroundImage?: string }>`
  width: 100vw;
  height: 100vh;

  margin: -8px 0 0 -8px;
  ${({ backgroundImage }) =>
    backgroundImage &&
    `
    background-image: url(${backgroundImage});
    background-size: cover;
  `}
`

export const LoginWrapper = styled.div`
  backdrop-filter: blur(6px) grayscale(10%) saturate(50%) brightness(20%);
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
`

export const LoginPrompt = styled.div`
  padding: ${rem(100)} ${rem(300)} ${rem(200)} ${rem(300)};
  background-color: ${colors.almostBlack};
  display: grid;
  grid-gap: ${rem(170)};
  align-items: center;
  justify-items: center;
  border-radius: ${WINDOW_BORDER_RADIUS};
`
