/* eslint-disable react/prop-types */

import React from 'react'
import ReactSelect, { ValueType } from 'react-select'
import { IOption } from '../../interfaces/schema/schema.interface'
import { Label } from '../../form2/fields/input/input.styles'
import { selectStyle } from '../../form2/fields/select/select.styles'
import { Grid } from '../../layout'

interface IFilterDropdownProps {
  name: string
  label?: string
  value?: { value: string | number; label: string | number }
  options: [{ value: string; label: string }]
  disableSelect?: boolean
  onChange: (s: string) => void
}

const FilterDropdown: React.FC<IFilterDropdownProps> = props => {
  const { value, options, onChange, name, label, disableSelect } = props

  return (
    <Grid>
      {!!label && <Label>{label}</Label>}
      <ReactSelect
        name={name}
        styles={selectStyle}
        placeholder={name}
        value={value && value.value ? (value as IOption) : { value: 'select', label: 'select' }}
        options={
          disableSelect ? (options as [IOption]) : ([{ value: 'select', label: 'Select' }, ...options] as [IOption])
        }
        onChange={(s: ValueType<IOption>) => onChange((s as IOption).value)}
      />
    </Grid>
  )
}

export default FilterDropdown
