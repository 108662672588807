import React from 'react'
import { useCookies } from 'react-cookie'
import { BrowserRouter as Router } from 'react-router-dom'
import InitApollo from './components/apollo'

import Login from './components/login'
import App from './app.component'
import { AUTH_COOKIE } from './config/variables.config'

const Bootstrap: React.FC = () => {
  const [cookies] = useCookies([AUTH_COOKIE])
  const token = cookies[AUTH_COOKIE]

  if (!token) return <Login />

  return (
    <Router>
      <InitApollo token={token}>
        <App />
      </InitApollo>
    </Router>
  )
}

export default Bootstrap
