/* eslint-disable indent */
import styled from 'styled-components/macro'
import { fonts, colors } from '../../layout'
import { INPUT_BORDER_RADIUS } from '../../config/layout.config'

export const RteWrapper = styled('button')<{ dark?: boolean; alternative?: boolean }>`
  background: none;
  padding: 0;
  border: 0;
  min-height: 150px;
  & * {
    font-family: ${fonts.osc};
  }
  & > div > div:first-of-type {
    text-align: left;
  }
  & > div {
    border-radius: ${INPUT_BORDER_RADIUS};
    min-height: 150px !important;
    ${({ alternative, dark }) =>
      alternative &&
      !dark &&
      `
    color: ${colors.white};
    background-color: ${colors.lightMidGrey};
    border: 1px solid ${colors.lightMidGrey};
    box-shadow: none;
  `}
    button {
      background-color: #fff !important;
    }

    ${({ alternative, dark }) =>
      alternative &&
      dark &&
      `
    color: ${colors.white};
    background-color: ${colors.darkMidGrey};
    border: 1px solid ${colors.darkMidGrey};
    box-shadow: none;
  `}
  }
`
