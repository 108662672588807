/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro'
import rem from '../helpers/rem.helper'
import { media } from './mediaqueries'

interface IGrid {
  alignContent?: string
  alignItems?: string
  areas?: string[]
  border?: string
  col?: string | number
  colGap?: string
  columnGap?: string
  flow?: string
  gap?: string
  height?: string
  justifyContent?: string
  justifyItems?: string
  justifySelf?: string
  margin?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  marginTop?: string
  mdCol?: string | number
  minRowHeight?: string
  overflow?: string
  padding?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
  paddingTop?: string
  row?: number | string
  rowGap?: string
  textAlign?: string
  textColor?: string
  xsCol?: string | number
  xxsCol?: string | number
  backgroundColor?: string
}

const autoRows = ({ minRowHeight = rem(20) }) => `minmax(${minRowHeight}, auto)`

const frGetter = (value: string | number) => (typeof value === 'number' ? `repeat(${value}, 1fr)` : value)

const iefrGetter = (value: number | string | undefined, gap: number | string) =>
  typeof value === 'number' ? `1fr (${gap} 1fr)[${value - 1}]` : value

const formatAreas = (areas: { map: (arg0: (area: string) => string) => { join: (arg0: string) => void } }) =>
  areas.map((area: string) => `"${area}"`).join(' ')

const flexChildWidthGetter = (col: string | number, gap: string) =>
  typeof col === 'number' ? `calc(100% /${col} - ${gap === '0px' ? '0.1px' : gap})` : ''

export const Grid = styled('div')<IGrid>`
display: -ms-grid;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
display: grid;
width: 100%;
grid-auto-rows: ${autoRows};
${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};

gap: ${({ gap = '.5rem' }) => gap && `${gap}`};
${({ colGap }) => colGap && `column-gap: ${colGap}`};
${({ rowGap }) => rowGap && `row-gap: ${rowGap}`};

${({ height }) => height && `height: ${height}`};
${({ flow }) => flow && `grid-auto-flow: ${flow}`};

margin: ${({ margin }) => margin && `${margin}`};
margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}`};
margin-right: ${({ marginRight }) => marginRight && `${marginRight}`};
margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft}`};
margin-top: ${({ marginTop }) => marginTop && `${marginTop}`};

padding: ${({ padding }) => padding && `${padding}`};
padding-bottom: ${({ paddingBottom }) => paddingBottom && `${paddingBottom}`};
padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}`};
padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}`};
padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}`};


${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
${({ justifyItems }) => justifyItems && `justify-items: ${justifyItems}`};
${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
${({ justifySelf }) => justifySelf && `justify-self: ${justifySelf}`};
${({ alignItems }) => alignItems && `align-items: ${alignItems}`};

${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
${({ textColor }) => textColor && `text-align: ${textColor};`}
${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
${({ row }) => row && `grid-template-rows: ${frGetter(row)}`};

/* Defining mediaquery based behaviour of items */

${media.xxs} {
  grid-template-columns: ${({ xxsCol = 1 }) => frGetter(xxsCol)};
  }
${media.xs}{
  ${({ xsCol }) => xsCol && `grid-template-columns: ${frGetter(xsCol || 2)}`}
  }
${media.md}{
  ${({ mdCol }) => mdCol && `grid-template-columns: ${frGetter(mdCol || 1)}`}
  }
${media.lg}{
  -ms-grid-columns: ${({ col, gap = '.5rem' }: IGrid) => iefrGetter(col, gap)};

  ${({ col }) => col && `grid-template-columns: ${frGetter(col || 1)}`}
  & > * {
    box-sizing: border-box;
    width: ${({ col = 1, gap = '.5rem' }) => flexChildWidthGetter(col, gap)};
  }
   @supports(display:grid) {
    & > * {
      width: initial;
    }
  }
  }
`
