import React from 'react'

export interface IDarkModeContext {
  dark: boolean
  children?: Element
}

export const DarkModeContext = React.createContext<IDarkModeContext>({
  dark: true,
})

export default DarkModeContext
