import React from 'react'

const NotFound: React.FC = () => {
  return (
    <div>
      <p>not found</p>
    </div>
  )
}

export default NotFound
