/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { QueryLazyOptions } from '@apollo/react-hooks'
import { WS_PRODUCT_TRANSLATIONS_QUERY } from '../../graphql/ws-product-translation/queries/ws-product-translation'
import { CONFIRM_POPUP } from '../popup/popup.map'
import PopupContext from '../../contexts/popup.context'
import { IWsProductTranslation } from '../../interfaces/ws-product-translation/ws-product-translation.interface'
import { CLONE_WS_PRODUCT_TRANSLATION_MUTATION } from '../../graphql/ws-product-translation/mutations/ws-product-translation'
import { CLONE_WS_PRODUCT_MUTATION } from '../../graphql/ws-product/mutations/ws-product'
import NotificationContext from '../../contexts/notification.context'
import { IWsProductListQuery } from '../../pages/ws-product/ws-product-list/ws-product-list'
import { CloneButton } from './clone-ws-product.styles'
import Icon from '../../layout/icons/icons.styles'

interface ICloneWsProduct {
  id: string
  wsid: string
  refreshProductList: (options?: QueryLazyOptions<IWsProductListQuery> | undefined) => void
}

const CloneWsProduct: React.FC<ICloneWsProduct> = props => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const { id, wsid, refreshProductList } = props

  /**
   * MUTATIONS
   */
  const [cloneWsProduct, { error: cloneWsProductMutationError, data: cloneWsProductMutationData }] = useMutation(
    CLONE_WS_PRODUCT_MUTATION,
  )
  const [cloneWsProductTranslation] = useMutation(CLONE_WS_PRODUCT_TRANSLATION_MUTATION)

  useEffect(() => {
    if (!cloneWsProductMutationError && cloneWsProductMutationData && cloneWsProductMutationData.cloneWsProduct) {
      refreshProductList()
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully cloned product!' })
    }
  }, [cloneWsProductMutationData])

  const [getWsProductTranslations, { data: wsProductTranslationsData }] = useLazyQuery(WS_PRODUCT_TRANSLATIONS_QUERY, {
    onCompleted: () => {
      popupCtx.show({
        id: 'clone-ws-product',
        type: CONFIRM_POPUP,
        options: { message: 'Are you sure?' },
        submit: () => {
          // create tag
          const tag = `${Math.floor(Math.random() * 100)}`

          // clone ws-product
          cloneWsProduct({ variables: { id, tag } })

          // clone ws-product-translations
          if (
            wsProductTranslationsData &&
            wsProductTranslationsData.wsProductTranslations &&
            wsProductTranslationsData.wsProductTranslations.length
          ) {
            const { wsProductTranslations } = wsProductTranslationsData as {
              wsProductTranslations: IWsProductTranslation[]
            }

            wsProductTranslations.forEach(translation => {
              cloneWsProductTranslation({ variables: { id: translation.id, tag } })
            })
          }
        },
      })
    },
  })

  const onClickClone = () => {
    getWsProductTranslations({ variables: { wsid } })
  }

  return (
    <CloneButton type="button" onClick={() => onClickClone()}>
      <Icon duplicate style={{ fontSize: '18px' }} />
    </CloneButton>
  )
}

export default CloneWsProduct
