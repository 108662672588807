import styled from 'styled-components/macro'
import rem from '../../helpers/rem.helper'

export const ModalSection = styled.div``

export const ModalOverlay = styled('div')<{ sidebarFixed?: boolean }>`
  transition-timing-function: linear, linear, ease;
  transition-property: top, bottom, width;
  transition-duration: 0.2s, 0.2s, 0.35s;
  background-color: rgba(0, 0, 0, 0.65);
  left: ${rem(75)};
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  top: 0;
  ${({ sidebarFixed }) =>
    sidebarFixed &&
    `
    left: ${rem(255)};
  `};
`

export const ModalContent = styled('div')<{ sidebarFixed?: boolean }>`
  transition-timing-function: linear, linear, ease;
  transition-property: top, bottom, width;
  transition-duration: 0.2s, 0.2s, 0.35s;
  text-align: center;
  left: ${rem(75)};
  position: fixed;
  cursor: pointer;
  z-index: 10000;
  overflow: auto;
  padding: 4px;
  bottom: 0;
  right: 0;
  top: 0;
  &:after {
    vertical-align: middle;
    display: inline-block;
    margin-left: -0.05em;
    height: 100%;
    content: '';
  }
  ${({ sidebarFixed }) =>
    sidebarFixed &&
    `
    left: ${rem(255)};
  `};
`

export const ModalDialog = styled('div')<{ large?: boolean }>`
  vertical-align: middle;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  cursor: default;
  max-width: auto;
  width: auto;
  outline: 0;
  ${({ large }) =>
    large &&
    `
    width: ${rem(800)};
  `};
`
