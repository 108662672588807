import React from 'react'
import { INotificationItem } from '../components/notification/notification-provider'

export interface INotificationContext {
  show(newItem: INotificationItem): void
}

export const NotificationContext = React.createContext<INotificationContext>({
  show: () => {},
})

export default NotificationContext
