export const getCurrent = (skipString: string, limitString: string): number => {
  const skip = parseInt(skipString, 10)
  const limit = parseInt(limitString, 10)
  return (skip + limit) / limit
}

export const getSkip = (current: number, limitString: string): string => {
  const limit = parseInt(limitString, 10)
  return `${limit * (current - 1)}`
}
