/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from 'react-apollo'
import NotificationContext from '../../../contexts/notification.context'
import { dataFormatter } from '../../../helpers/data-formatter'
import { PageHeader, FlexItem, Heading } from '../../../layout'
import FormProvider from '../../../form2/form.provider'
import {
  LOCALE_OPTIONS,
  TAX_OPTIONS,
  WS_ORDER_SHIPPING_STATUS_OPTIONS,
  WS_ORDER_PAYMENT_STATUS_OPTIONS,
  WS_ORDER_ORDER_STATUS_OPTIONS,
} from '../../../config/select-options.config'
import { WS_ORDER_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { WS_ORDER_QUERY } from '../../../graphql/ws-order/queries/ws-order'
import { UPDATE_WS_ORDER_MUTATION, DELETE_WS_ORDER_MUTATION } from '../../../graphql/ws-order/mutations/ws-order'
import { IWsProduct } from '../../../interfaces/ws-product/ws-product.interface'
import { IWsOrder } from '../../../interfaces/ws-order/ws-order-interface'
import { MAIN_BRAND_COLOR } from '../../../config/layout.config'

const WsOrderDetails: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { id: qId } = useParams() as { id: string }
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IWsOrder>()
  const [wsProductOptions, setWsProductOptions] = useState<IOption[]>()

  /**
   * QUERIES
   */
  const { error: queryError, data: queryData } = useQuery(WS_ORDER_QUERY, { variables: { id: qId } })
  useEffect(() => {
    if (queryData && queryData.wsOrder) {
      const { wsOrder } = queryData
      const fd = dataFormatter(wsOrder, { populatedFields: ['product'] })
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IWsOrder)

      if (queryData.wsProducts && queryData.wsProducts.length) {
        setWsProductOptions(
          queryData.wsProducts.map(({ id: cId, name: cName, price: cPrice }: IWsProduct) => ({
            label: `${cName}`, // - [${cPrice}]`,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateFormSubmission, { error: mutationError, data: mutationData }] = useMutation(UPDATE_WS_ORDER_MUTATION)
  const [deleteWsOrder, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_WS_ORDER_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateWsOrder) {
      const { updateWsOrder: updateWsOrderData } = mutationData
      const fd = dataFormatter(updateWsOrderData, { populatedFields: ['product'] })
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData as IWsOrder)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Form Submission Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting Order!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Order Deleted!' })
      setTimeout(() => {
        window.location.href = `/ws-orders`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }

    const { total_net: tn, total_gross: tg, ...filteredNewData } = newData
    if (filteredNewData.items && filteredNewData.items.create && filteredNewData.items.create.length) {
      let filteredItems = []
      filteredItems = filteredNewData.items.create.map((item: any) => {
        const { total_gross, total_net, unit_price, variation_price, ...filteredItem } = item
        return filteredItem
      })
      filteredNewData.items.create = filteredItems
    }
    updateFormSubmission({ variables: { id: itemID, data: filteredNewData } })
  }

  const onDelete = () => {
    deleteWsOrder({ variables: { id: itemID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { total_net, total_gross } = snapShot

  return (
    <div>
      <PageHeader title="Order Details" />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FlexItem margin="0px 0px 15px 0px">
            <Heading as="h6" amount textColor={MAIN_BRAND_COLOR}>
              Total Net: {total_net} EUR
            </Heading>
            <Heading as="h6" amount textColor="white">
              Total Gross: {total_gross} EUR
            </Heading>
          </FlexItem>

          <FormProvider
            onDelete={onDelete}
            deleteVerifier={qId}
            onSubmit={onUpdate}
            schema={WS_ORDER_UPDATE_SCHEMA}
            variables={{
              LOCALE_OPTIONS,
              TAX_OPTIONS,
              WS_ORDER_ORDER_STATUS_OPTIONS,
              WS_ORDER_PAYMENT_STATUS_OPTIONS,
              WS_ORDER_SHIPPING_STATUS_OPTIONS,
              WS_PRODUCT_OPTIONS: wsProductOptions,
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default WsOrderDetails
